@import "../../../../../../../shared//styles/styles.scss";

.leaders-information {

    &__icon-container {
        width: 100px;
        margin-left: 25px;

        &__img {
            width: 75px;
        }

        &__icon {
            margin-left: 45%;
            margin-top: -30%;
            width: 30px;
            height: 30px;
            z-index: 1;
        }

        &__count {
            width: 25px;
            height: 25px;
            align-self: flex-end;
            border-radius: 100%;
            background-color: $color-royal-blue;
            position: relative;
            right: 25px;
            @include global-typography($white, 20px, 15px, 600);
        }

        &__text {
            @include global-typography($color-meteorite, 28px, 22px, bold);
        }
    }

    &__summary {
        height: 85%;
        border: 1px solid $color-athens-gray;
        border-radius: 14px;
        padding: 3%;
        margin-top: 10px;
        overflow-y: scroll;
        position: relative;
        display: relative;

        &__panel {
            position: relative;
            padding: 5px;
        }

        &__img {
            width: 100%;
            height: 210px;
            text-align: center;
        }

        &__icon-container {
            width: 100%;
            height: 120px;
            position: relative;

            &__icon-content {
                width: 80%;
                height: 120px;
                margin-left: 10%;
            }

            &__icon {
                width: 100%;
                height: 100%;
                position: relative;
            }

            &__count {
                width: 45px;
                height: 45px;
                border-radius: 100%;
                background-color: $color-royal-blue;
                position: absolute;
                bottom: 0;
                right: 20px;
                @include global-typography($white, 20px, 20px, 600);
            }

            &__text {
                @include global-typography($color-meteorite, 28px, 21px, bold);
            }
        }

        &__box {
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px;
            border-radius: 14px;
            background-color: $color-athens-gray-light;
            border: 1px solid $color-athens-gray;
            @include global-typography($color-amethyst-smoke, 13px, 12px, 500);

            &__third {
                @extend .leaders-information__summary__box;
                background-color: $color-athens-gray-light;
                margin-top: 10px;
            }

        }
    }

    &__description {
        height: 85%;
        border: 1px solid $color-athens-gray;
        border-radius: 14px;
        padding: 3%;
        margin-top: 10px;
        overflow-y: scroll;
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }

        &__text-description {
            @include global-typography($color-amethyst-smoke, 14px, 16px, 500)
        }
    }
}
@import "../../../../../shared/styles/styles.scss";

.hierarchy {
    width: 100%;
    height: 83vh;
    overflow-y: scroll;
    
    &::-webkit-scrollbar{
        display: none;
    }
    &__navbar{
        background-color: $color-whisper;
        justify-content: space-between;
        padding: 20px;
        position: sticky;
        top: 0;
        z-index: 1;

        &__back-button{
            &__icon{
                transform: rotate(90deg);
            }
        }

        &__title{
            margin-left: -16%;
        }

        &__icon{
            width: 22px;
        }
        &__text{
            @include global-typography($color-meteorite, 14px, 16px, bold);
            margin-left: 5px;
        }
    }
    &__content {
        width: 100%;

        &__container-items{
            min-height: 60px;
            display:flex;
            flex-direction:row;
            border-radius: 10px;
            position: relative;
            width: 100%;
            margin: 0px 2% 0px 2%;
        }

        &__level-state {
            width: 12px;
            height: 12px;
            border: 2px solid $color-royal-blue;
            border-radius: 100%;
            padding: 2px;
            transform: width 1s, height 1s;

            &__level{
                @include global-typography($color-meteorite, 20px, 20px, bold);
                margin-right: 5px;
                text-align: center;
            }
            
            &--active {
                @extend .hierarchy__content__level-state;
                width: 22px;
                height: 22px;
            }
            
            &__state{
                width: 100%;
                height: 100%;
                border-radius: 100%;
                background-color: $color-royal-blue;
            }
            
            &__line{
                height: 60px;
                width: 0px;
                visibility: hidden;
                border: 1px solid $color-royal-blue;
                position: relative;
                margin-top: -73px;
                margin-left: -17px;
                &--visible{
                    @extend .hierarchy__content__level-state__line;
                    visibility: visible;
                }
            }
        }

        &__line {
            top: -22px;
            left: 2%;
            position: relative;
        }

        &__modal {
            width: 100%;

            &__overlay {
                opacity: 0;
                position: fixed;
                top: 80%;
                width: 100%;
                height: 13%;
                transition: opacity 500ms ease-in-out;
                z-index: -1;

                &--after-open{
                    opacity: 1;
                }
                
                &--before-close{
                    opacity: 0;
                }
            }
        }
    }
   
    &__content-drag{
        padding: 10px;
        min-height: 68%;
    }

    &__body {
        overflow: hidden;
    }
   
    &__container {
        align-items: center;
        background-color: $color-athens-gray;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        height: 75px;
        justify-content: flex-start;
        margin: 5px 2.5px 5px 0px;
        padding: 0px 15px 0px 15px;
        transition: 0.2s ease-out;
        @include global-typography($color-amethyst-smoke, 18px, 18px, bold);

        &__short{
            margin-right: 0px;

            &:last-child{
                margin-left: 2.5px;
                margin-right: 2.5px;
            }
        }

        &__small{
            height: 37px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &__level{
            @include global-typography($color-amethyst-smoke, 18px, 18px, normal);
        }

        &--active{
            @extend .hierarchy__container;
            background-color: $color-periwinkle-gray;
            color: $color-meteorite;

            &__level{
                font-weight: normal;
            }
        }

        &__user{
            padding-left: 0px;
            width: 100%;

            &--mod{
                @extend .hierarchy__container__user;
                padding-left: 20px;
            }
        }
    }

    &__information-card{
        border: 2px dashed $color-blue-haze;
        border-radius: 10px;
        margin:  0px 5% 60px 5%;
        min-height: 110px;
        padding: 20px;
        width: 90%;
        @include global-typography($color-amethyst-smoke, 14px, 16px, 500);

        &__icon{
            width: 7px;
        }
    }

    &__button-container {
        width: 100%;
        min-height: 110px;
        margin-bottom: 60px;

        &__text{
            // width: 70%;
            text-align: center;
            @include global-typography($color-meteorite, 19px, 20px, normal);
            
            &--bold{
                @extend .hierarchy__button-container__text;
                font-weight: bold;
            }
        }
        
        &__confirm {
            margin-top: 10px;
            @include global-button(42px, 80%);
            @include global-typography($color-white, 18px, 20px, bold)
        }
        
        &__back{
            margin-top: 10px;
            @include global-typography($color-meteorite, 18px, 18px, normal);
        }
    }

    &__text {
        font-size: 12px;
        color: $dark-gray;
        font-weight: 600;
    }

    &__line {
        background-color: #B9B9B9;
        height: 25px;
        width: 2px;

        &__circle {
            height: 8px;
            width: 8px;
            border: 2px solid #B9B9B9;
            border-radius: 100%;
        }

        &--mod{
            height: 15px;
        }
    }

    &__modal {
        width: 90%;
        height: 450px;
        background: $white;
        border-radius: 8px;
        padding: 20px;
        transition: height .5s ease;

        &--success{
            @extend .hierarchy__modal;
            height: 360PX;
        }
    
        &__overlay {
            background-color: $color-overlay-amethyst-smoke;
            backdrop-filter: blur(3px);
            z-index: 2;
            opacity: 1;
            position: fixed;
            bottom: 0;
            width: 100%;
            height: 100%;
            overflow: scroll;
        }

        &__container{
            height: 100%;
        }

        &__success {

            &__icon {
                height: 50px;
                width: 50px;

                &--mod {
                    height: 30px;
                    width: 30px;
                }
            }

            &__line{
                border: 1px solid $color-royal-blue ;
                height: 20px;
            }
            
            &__circle{
                width: 8px;
                height: 8px;
                border-radius: 100%;
                border: 2px solid $color-royal-blue ;
            }

            &__text{
                @include global-typography($color-meteorite, 19px, 20px, 500);
                width: 85%;
                
                &__bold{
                    @include global-typography($color-meteorite, 19px, 20px, bold);
                }
                &__title{
                    @include global-typography($color-meteorite, 21px, 24px, bold);
                }
            }
        }

        &__adjust{
            @include global-typography($color-meteorite, 18px, 18px, 500);

            &__icon{
                width: 23px;
                margin-right: 10px;
            }

            &__title{
                width: 100%;
                @include global-typography($color-meteorite, 18px, 18px, bold);
            }

            &__user{
                width: 100%;
                border-bottom: 1.5px solid $color-box-shadow;
                
                &__img {
                    height: 80%;
                    text-align: center;
            
                    &__count {
                        width: 20px;
                        height: 20px;
                        align-self: flex-end;
                        border-radius: 100%;
                        background-color: $color-royal-blue;
                        position: relative;
                        right: 20px;
                        bottom: 10px;
                        @include global-typography($white, 20px, 13px, 600);
                    }
                }

                &__info{
                    height: 50%;
                    justify-content: flex-end;
                    &__name{
                        @include global-typography($color-meteorite, 21px, 24px, bold);
                        padding-top: 3px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            
            &__description{
                text-align: center;
            }

            &__selects{
                overflow-y: scroll;
                padding: 5px 10px 0px 10px;

                &__radius{
                    width: 22px;
                    height: 22px;
                    border: 2px solid $color-amethyst-smoke;
                    border-radius: 100%;
                    padding: 2px;
                    transform: width 1s, height 1s;
                    
                    &--active{
                        @extend .hierarchy__modal__adjust__selects__radius;
                        border-color: $color-royal-blue;
                    }
                    
                    &__state{
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        background-color: transparent;
                        transform: background-color 1s;
                        
                        &--active{
                            @extend .hierarchy__modal__adjust__selects__radius__state;
                            background-color: $color-royal-blue;
                        }

                    }
                }
                &__hierarchy{
                    margin-left: 15px;
                    @include global-typography($color-meteorite, 18px, 18px, bold);
                }
            }
        }
        
        &__button{
            @include global-button(60%, 100%);
            @include global-typography($white, 20px, 18px, bold);
        }
    }

}
@import "../../../../../shared/styles/styles.scss";

.box-member {

    &__show{
        display: none
    }

    &__content {
        padding: 0 10px;
        width: 100%;
    }

    &__container {
        background-color: $color-whisper;
        padding-top: 10px;
        min-width: 0;
        border-radius: 8px;
        width: 100%;
        max-width: 420px;
        flex-direction: column;
        align-items: center;
        display: flex;
        transition: 0.2s ease-out;
        justify-content: flex-start;

        &--mod {
            padding: 13px;
        }

        &__button-setting {
            width: 20px;
            
        }
    }

    &__header {
        width: 96%;
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 15px 0px 15px 0px;

        &__name{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-bottom: 2px;
            text-align: start;
            @include global-typography($color-meteorite, 18px, 18px, bold);
            
            &__label{
                @include global-typography($color-meteorite, 14px, 16px, 500);
            }
        }

        &__member-info{
            width: 95%;
        }

        &__button-go-back {
            transform: rotate(90deg);
        }

    }

    &__button {
        color: $color-meteorite;
        line-height: 0;
        font-size: 12px;

        &__icon {
            transform: rotate(270deg);
            transition: transform 0.3s ease-in-out;
            font-size: 18px;
            color: $color-royal-blue;
            margin-left: 3px;
            &--mod {
                transform: rotate(90deg);
                transition: transform 0.3s ease-in-out;
                font-size: 18px;
                color: $color-royal-blue;
                margin-left: 3px;
            }
        }

        &__content{
            width: 100%;
            height: 35px;
            justify-content: center;
            display: flex;
        }
    }

    &__member {
        background-color: $white;
        width: 100%;
        height: 97px;
        margin-top: 1%;
        border-radius: 10px;

        &__header {
            width: 96%;
            padding: 10px 12px;
            background: $color-white;
            display: flex;
            justify-content: space-between;
            border-radius: 8px 8px 0px 0px;
            border-bottom: 2px solid $color-whisper;
        }

        &__subtitle {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            @include global-typography($color-amethyst-smoke,18px,14px,500);
        }
        
        &__level {
            width: 48%;
            @include global-typography($color-meteorite,18px,18px,500)
        }

        &__icon {
            color: $dark-gray;
            margin-left: 5px;
            margin-top: 2px;

            &__more{
                font-size: 10px;
                color: $color-meteorite;
            }

            &--mod {
                margin-top: 4px;
            }

            &__num {
                margin-left: 2px;
            }
        }

        &__title-affiliates {
            @include global-typography($color-amethyst-smoke,12px,14px,bold);
            max-width: 215px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__img {
            width: 50px;
            height: 50px;
            border-radius: 100%;

            &--inactive {
                opacity: 0.5;
            }

            &--pending{
                @extend .box-member__member__img;
                filter: opacity(0.5) ;
            }
        }

        &__content {
            margin-top: 1%;

            &__view-more {
                display: flex;
                position: relative;
                flex-direction: column;
                padding: 7px 5px;
                height: 100px;
            }
        }

        &__name {
            @include global-typography($color-meteorite,16px,18px,500);
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            width: 100%;

            &__names{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 20px;
            }

            &__last-name{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 20px;
            }

            &--mod {
                opacity: 0.5;
            }
        }

        &__button {
            height: 100px;

            &--active {
                background: #EEEEEE;
                border-radius: 10px;
            }
        }

        &__box {
            @include global-pop-up-card;

            &--open{
                @include global-pop-up-card__open;
            }

            &__details-user {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding: 5px 10px;
                margin-top: 10px;
                margin-bottom: 5px;
            }

            &__button{
                width: 100%;
            }
        }

        &__more {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: 50px;
            border-radius: 50px;
            margin: 0 auto;
            background-color: $color-amethyst-smoke;
            @include global-typography($color-white,16px,18px,bold);


            &__text {
                @include global-typography($color-meteorite,16px,18px,500);
                text-align: center;
                margin-bottom: 0px;
                width: 55px;
                margin-top: 5px;
            }
        }

        &__card-content{
            padding: 10px 15px 10px 15px;

            &__text{
                text-align: center;
                @include global-typography($color-meteorite,18px, 18px, 500);
            }
        }

    }

    &__text {
        @include global-typography($color-meteorite,14px,16px,500);
        margin-left: 21px;
    }

    &__line {
        background-color: #B9B9B9;
        height: 17px;
        width: 2px;

        &__circle {
            height: 8px;
            width: 8px;
            border: 2px solid #B9B9B9;
            border-radius: 100%;
        }
    }

    &__modal-settings{
        height: 50px;
        width: 50px;
        background-color: red;

        &__overlay{
            background-color: transparent;
        }
    }
    
    &__options{
        &__button{
            width: 50%;

            &--mod{
                border-left: 1px solid $color-amethyst-smoke;
                padding-left: 5px;
                width: calc(50% - 5px);
            }

            &__icon{
                width: 23%;
                height: 23px;
            }

            &__text{
                width: 70%;
                text-align: left;
                margin-left: 5px;
                @include global-typography($color-amethyst-smoke, 12px, 10px, bold);

            }
        }

        &__line{
            background-color: $color-amethyst-smoke;
            height: 100%;
            width: 1px;
            margin: 0px 6px;
            
            &--mod{
                @extend .box-member__options__line;
                margin: 0px 4px 0px 4px;
            }
        }
        
    }

    &__modal-not-member{
        height: 145px;
        width: 240px;
        background-color: $white;
        border-radius: 10px;

        &__close{
            width: 12px;
            margin-top: 0.5rem;
        }

        &__content{
            height: 100px;
        }

        &__text{
            font-size: 14px;
            color: $color-meteorite;
            font-weight: 400;
            width: 70%;
            line-height: 13px;

            &--mod{
                font-weight: bold;
                width: 100%;
            }
        }

        &__icon{
            margin-bottom: 3%;
            width: 3rem;
        }

    }

    &__delete-modal {
        position: fixed;
        bottom: 0;
        background: $white;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        padding: 10px 20px 35px;
        &__overlay {
            z-index: 2;
            background-color: #6a6a6a5e;
            opacity: 1;
            position: fixed;
            bottom: 0;
            width: 100%;
            height: 100%;

            &--mod{
                z-index: 2;
            }
        }

        &__body-open{
            overflow: hidden;
        }

        &__top-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            &__icon {
                height: 2em;
                width: 2em;
            }
        }
        &__container-content {
            padding: 0 2rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            &__img-container {
                margin: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &__img {
                height: 80px;
                width: 80px;
                border-radius: 100%;
                justify-self: center;
            }
            &__text {
                color: #707070;
                width: 280px;
                font-size: 14px;
                margin: 0;
                line-height: 1;
                text-align: center;
                &--mod {
                    font-weight: bold;
                }
            }
        }
        &__button {
            margin: 5% 0 0;
            width: 90%;
            border-radius: 10px;
            background-color: #e6e6e6;
            color: #8a8a8a;
            font-weight: 500;
            padding: 10px;
        }
    }

    &__delete-modal{

        &__text{
            padding-left:3px;
            font-size:12px;
            color:gray;
            overflow:hidden
        }
    }

    &__pending-icon{
        margin-left: 50%;
        margin-top: -26%;
        margin-bottom: 0;
        width: 20px;
        height: 20px;
        z-index: 1;
    }

    &__number-member {
        @include global-typography($color-white,16px,10px,600);
        color: white;
        display: flex;
        background-color: #5437E9;
        padding: 8px;
        margin-left: 50%;
        margin-top: -26%;
        margin-bottom: 0;
        width: 20px;
        height: 20px;
        border-radius: 40px;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }
}


//media queries
//large

@media screen and (min-width: $media-query-medium) {

    .box-member {

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }

        &__text {
            display: none;
        }

        &__member {
            height: 156px;

            &__img {

                &--active {
                    height: 75px;
                    width: 75px;
                }
            }

            &__name {

                &--active {
                    display: none;
                }
            }



        }

        &__line {
            background-color: #B9B9B9;
            height: 40px;
            width: 3px;

            &__circle {
                height: 10px;
                width: 10px;
                border: 2px solid #B9B9B9;
                border-radius: 100%;
            }
        }

        &__child {
            background-color: #FEFEFE;
            height: 100%;
            width: 100%;

            &__name {
                justify-content: space-between;
            }

            &__button {
                width: 90px;
            }

        }

        &__delete-modal {
            &__overlay {
                display: flex;
                justify-content: center;
            }
        }
    }
}

//media queries
//small
@media screen and (max-width: $media-query-small) {
    .box-member {
        &__delete-modal {
            height: 50%;
            width: 100%;
        }

        &__number-member {
            width: 18px;
            height: 18px;

            &--mod {
                font-size: 0.4rem;
            }
        }
    }
}

@import '../../../shared/styles/styles.scss';

.validate-code-page {
    
 
    
        &__input {
            
            padding: 10px;
            display: flex;

            &__error {
                color: red;
                font-weight: $global-weight-bold;
                font-size: 11px;
                text-align: center;
            }
            &-text{
                @include global-typography($color-meteorite,14px,16px,normal);
            }
        }

    &__footer{
        background-color: $color-white;
        width: 100%;
        padding: 20px;

        &__line{
            height: 25px;
            width: 1px;
            background-color: $color-blue-haze;
        }

        &__content{
            justify-content: space-evenly;
        }

        &__button{
            color: $color-meteorite;
            font-size: 20px;
        }
    }

    &__countdown{
        @include global-typography($color-amethyst-smoke,17px,17px, bold);

        &__text-container{
            margin-right: 5px;
        }
    }

    &__user{
        padding: 10px 0 0 0;
        justify-content: space-between;
        height: 57vh;
        

        &__text{
            @include global-typography($color-meteorite,18px,16px,normal);
            line-height: 16px;
            width: 310px;
            
            &--phone{
                @include global-typography($color-royal-blue,18px,18px,bold);

            }
            &--mod{
                @extend .validate-code-page__user__text;
                @include global-typography($color-meteorite,14px,18px,normal);
            }
            &--mood{
                @extend .validate-code-page__user__text;
                @include global-typography($color-royal-blue,18px,18px,bold);

            }
            &--text{
                @include global-typography($color-meteorite,20px,20px,bold);
                width: 80%;
            }
        }
    }

    &__button {
        &[type=submit] {
            @include global-button(40px,94%);
            @include global-typography($color-white,20px,18px,bold);
            line-height: 40px;
            margin: 5px 0 0 0;
        }

        &:disabled{
            opacity: 0.2;
        }

        &__container{
            margin-top: 5px;
            margin-bottom: 7px;
        }

        &__text{
            @include global-typography($color-amethyst-smoke,18px,18px,normal);
            margin: 0 0 20px 0;
        }
    }
}


//media query
@media (max-width: $media-query-medium) {
    .validate-code-page {
        &__user{
            height: 57vh;
        }
    }
}
//landscape

@media screen and (orientation: landscape) {
    .validate-code-page {
        &__user{
            height: 60vh;
        }
        &__button{
            height: 60vh;
        }
    }
}
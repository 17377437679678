@import "../../../shared/styles/styles.scss";


.header-logo {


    &__header {
        width: 100%;
        @include gradient-royal-dull;
        padding: 40px 30px 0 30px;
        margin: 0%;
        align-items: center;
        border-radius: 0% 0% 3% 3%;
        opacity: 1;
        height: 40%;
        @include global-animation-header;
        animation-duration: 4s;
        animation-iteration-count: 1;

        &__head{
            width: 100%;
            @include gradient-royal-dull;
            padding: 40px 30px 45px;
            margin: 0%;
            align-items: center;
            border-radius: 0% 0% 3% 3%;
            height: 40%;
            opacity: 1;
            animation-iteration-count: 0;
        }
        
        &__img {
            width: 200vh;
            height: 100%;
            position: absolute;
            margin:0%;
            padding: 0;
            visibility: hidden;
            animation-duration: 2.5s;
            @include global-animation-img;
            object-fit: cover;
            animation-iteration-count: 1;
        }

            &__logo {

                height: 180px;
                width: 180px;
                border-radius: 15px;

                &__content {
                    width: 100%;
                    height: 80%;
                }

                &__img {
                    display: inline-block;
                    position: absolute;
                    max-width: 100%;
                    height: auto;
                }
            }

            &__container {
                width: 100%;


                &__text {
                    text-align: center;
                    width: 90%;
                    display: inline-block;
                    position: absolute;
                    padding: 20px;
                    @include global-typography($color-athens-gray-light, 20px, 20px, bold);
                    visibility: hidden; 
                    animation-duration: 2.5s;
                    @include global-animation-text;
                    animation-iteration-count: 1;
                    
                    &__mod{
                        text-align: center;
                        width: 90%;
                        display: inline-block;
                        position: absolute;
                        padding: 20px;
                        @include global-typography($color-athens-gray-light, 20px, 20px, bold);
                        visibility: hidden;
                        animation-iteration-count: 0;
                    }
                }
            }
        
    }
}
@import '../../../shared/styles/styles.scss';

.map-page {   
    padding: 20px;

    &__map-container{
        width: 100%;
        height: 300px;
    }

    &__loading{
        background: $color-whisper;
        height: 300px;
        width: 100%;
        &__text{
            @include global-typography($color-meteorite, 18px, 18px, bold);
            padding-top:40px;
        }
        &-spinner{
            animation: spinner 1.5s linear infinite;
        }
    }

    &__error{
        background: $color-whisper;
        height: 300px;
        width: 100%;
        &__container{
            width: 100%;
            padding: 30px 70px 0 70px;
        }
        &__text{
            @include global-typography($color-meteorite, 18px, 18px, bold);
        }
    }

    &__container {
        width: 100%;
    }

    &__input.MuiFormControl-root {

        width: 100%;

        &>label {

            &.MuiFormLabel-root {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -15px;
                left: -10px;
                &+div>div{
                    top: 15px;
                    right: 0;
                }
            }

            &.MuiInputLabel-shrink {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -5px;
                transform: translate(14px, -14px);

                &+div>div{
                    top: auto;
                }
            }

            &.Mui-disabled {
                color: $color-amethyst-smoke;
            }
        }


        &>div {

            &:hover {
                &>fieldset {
                    border-color: $color-amethyst-smoke;
                }
            }

            &.Mui-focused {
                &>fieldset {
                    border-bottom: 2px solid $color-blue-haze;
                }
            }

            &.Mui-error {
                &>input {
                    &+fieldset {
                        border-bottom: 1px solid $color-blue-haze;
                    }
                }
            }

            &>input {
                padding: 0 0 0 4px;
                box-shadow: none;
                border: 0;
                background: transparent;
                cursor: text;
                @include global-typography($color-amethyst-smoke, 20px, 18px, bold);
                -webkit-text-fill-color: $color-amethyst-smoke;

                &:disabled {
                    background-color: transparent;
                }

                &+fieldset {
                    border: 0;
                    border-bottom: 1px solid $color-blue-haze;
                    border-radius: 0;
                }
            }

            &.Mui-disabled {
                background-color: $color-blue-haze;
            }

        }

    }

    &__input-autocomplete.MuiFormControl-root {
        @extend .map-page__input;
        padding-left: 0;

        &>label {

            &.MuiFormLabel-root {
                left: -10px;
                top: 0;
            }
        }

        &>div,
        >.Mui-error {
            padding: 0;


            &>fieldset {
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid $color-blue-haze;
            }
        }

    }

    &__details-container {
        display: flex;
        flex-direction: column;
        background-color: rgb(230, 230, 230);
        padding: 5px;
        align-items: center;
        color: rgb(107, 107, 107);

        &__notFound{
            height: 75px;
            width: 96%;
            background-color: $white;
            margin-bottom: 25px;
            border-radius: 8px;
            @include global-typography($color-blue-haze, 18px, 18px, bold);
        }

        &__select:focus {
            box-shadow: none;
            border-width: 0px;
            border-bottom-color: rgb(139, 139, 139);
            border-bottom-width: 1px;
            margin: 0;
        }

        &__select {
            border-width: 0px;
            border-bottom-color: rgb(139, 139, 139);
            border-bottom-width: 1px;
            margin: 0;
        }


        &__no-data {
            display: flex;
            text-align: center;
            width: 300px;
            height: 110px;
            color: #bbbbbb;
            border: dashed 2px $color-box-shadow;
            justify-content: center;
            align-items: center;
            padding: 25px;
        }

        &__results {
            justify-content: center;
            height: 35px;
        }

        &__text {
            @include global-typography($color-meteorite, 20px, 18px, bold);
            width: 80px;

            margin: 0;
            &__results{
                @include global-typography($color-meteorite, 20px, 18px, bold);
            }

            &--mod {
                width: 75px;
                @include global-typography($color-meteorite,16px,18px,bold);
                margin-top: 5px;
                display: flex;
                flex-direction: column;
                width: 100%;

                &__name{
                    width: 75px;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            
                &__lastName{
                    width: 65px;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
        }

            &--filter {
                margin-left: 3px;
                font-size: 0.9rem;
                margin-bottom: 0;
            }

            &__no-data {
                @include global-typography($color-blue-haze, 12px, 15px, normal);

                &--icon-search {
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;
                    margin-bottom: 20%;

                    > button {
                        @include global-typography($color-white, 20px, 18px, bold);
                        background: $color-royal-blue;
                        width: 90%;
                        height: 42px;
                        border-radius: 10px;

                        &:disabled{
                            opacity: 0.2;
                            background: $color-blue-haze;
                            color: $color-meteorite;
                        }
                    }
                }

                &--select {
                    margin-right: 12px;
                }
            }

            &__select {
                display: flex;
                flex-direction: column;
            }
        }

        &__details-filters {
            width: 100%;
            position: relative;

            &__image {
                position: absolute;
                top: 9px;
                left: 5px;
            }
        }

        &__user-container {
            background-color: $color-whisper;
            border-radius: 8px;
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 10px;
            align-content: center;

            &--items {
                @include global-pop-up-card;
                border-radius: 5px;
            }

            &--active {
                @include global-pop-up-card__open;
                border-radius: 5px;
                overflow-y: scroll;

                &::-webkit-scrollbar{
                    display: none;
                }
            }
        }

        &__details-user {
            display: flex;
            flex-direction: column;
            height: 75px;
            padding: 5px;
            height: 100%;
        }

        &__number-votes {
            color: white;
            display: flex;
            background-color: rgb(107, 106, 106);
            padding: 4px;
            margin-left: 48%;
            margin-top: -25%;
            margin-bottom: 0;
            width: 20px;
            height: 20px;
            font-size: 0.8rem;
            border-radius: 40px;
            align-items: center;
            justify-content: center;
        }

        &__collapse-icon {
            transition: transform 0.6s ease-in-out;
            transform: rotate(0deg);
            margin-left: 5px;

            &--activated {
                transition: transform 0.6s ease-in-out;
                transform: rotate(180deg);
                margin-left: 5px;
            }
        }

        &__member-text-found {
            @include global-typography($color-meteorite, 20px, 14px, normal);
            height: 30px;
        }

        &__search-container {
            display: flex;
            justify-content: space-between;
            width: 98%;
        }

        &__leaked-members {
            width: 100%;
            padding-top: 20px;
        }

        &__button-clean {
            align-items: flex-end;
        }
    }

    &__map-container {
        width: 100%;
        height: 300px;
        transition: height 0.6s ease-in-out;

        .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
            display: none;
        }
    }


    &__report {

        &__container {
            margin-top: 5%;
        }

        &__back-text {
            @include global-typography($color-meteorite, 20px, 14px, bold);
            text-align: center;
            width: 160px;
        }

        &__back-img {
            height: 75px;
            width: 75px;
            border-radius: 50%;
            background-color: $color-royal-blue;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 34px;
                height: 43px;
            }
        }

        &__buttons {
            width: 120px;
            height: 40px;
            border-radius: 8px;
            font-size: 13px;
            color: $color-meteorite;
            display: flex;

            &__save {
                background-color: $color-royal-blue;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                margin-bottom: 60%;
                @include global-typography($color-white, 20px, 14px, bold);

                img {
                    height: 13px;
                }

                &:hover{
                    color: $white;
                }
            }

            &--back {
                align-self: flex-start;
                margin: 2vh 0 0 3vh;
                height: 25px;
                font-size: 16px;
                font-weight: bold;
            }

        }

        &__back-icon {
            transform: rotate(90deg);
            height: 9px;
        }

    }

    &__image {
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }

    &__title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    &__Buttons {
        font-size: 1rem;
        border-radius: 5px;
        padding: 0.5rem;
        margin: 0.5rem;
        border: 2px solid rgb(0, 0, 0);

        &--active {
            background-color: rgb(0, 36, 216);
            color: white;
        }
    }
    &__form-select > #demo-simple-select-standard {
        display: flex;
        align-items: center;
    }

    &__select{

        &.MuiInputBase-root {
            
            width: 100%;
            height: 30px;
            margin-bottom: 7%;
    
            &>div.MuiSelect-select{
    
                padding: 0;
                height: 30px;
    
                &>li{
                    padding: 0px 20px 15px 30px;
                    @include global-typography($color-meteorite,20px,18px,bold);
                }
            }
    
            &>fieldset,
            &.Mui-focused>fieldset{
                border: 0;
                border-bottom: 1px solid $color-blue-haze;
                border-radius: 0;
            }
    
        }
        &__options.MuiMenuItem-root{
            @include global-typography($color-meteorite,23px,18px,bold);
            border-bottom: 1px solid $color-cadet-blue;
            margin: 0 10px;
            background: $color-whisper;
            
        }

        &__icon.css-3qbkez-MuiSelect-icon,
        &__icon.css-10q54uo-MuiSelect-icon{
            position: absolute;
            right: 0px;
            top: 12px;
        }
    }
    

    &__button-search {
        &[type=submit] {
            @include global-button(40px,94%);
            @include global-typography($color-white,20px,18px,bold);
            line-height: 40px;
            margin: 5px 0 0 0;
        }

        &:disabled{
            opacity: 0.2;
        }

        &__container{
            margin-top: 5px;
            margin-bottom: 7px;
        }

        &__text{
            @include global-typography($color-amethyst-smoke,18px,18px,normal);
            margin: 0 0 20px 0;
        }
    }
}

.cluster>div {
    top: 5px;
    left: 5px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiAutocomplete-paper {
    @include global-typography($color-meteorite,23px,18px,bold);

}

#map-page-list-locations {
    margin: 20px 8px 8px 0px;
    width: 100%;

    .MuiInputBase-root.MuiInput-root {
        @include global-typography($color-meteorite,25px,18px,bold);

        &::before {
            border-bottom: 1px solid #C7c6dc;
        }

        .MuiSelect-select.MuiSelect-standard {
            padding-bottom: 0;
        }

        svg {
            color: $color-meteorite;
        }
    } 
}

@media screen and (min-height:670px) {
    .map-page {
        &__map-container {
            &--mod {
                height: 45vh;
            }
        }
    }
}

@media screen and (min-height: 745px) {
    .map-page {
        &__map-container {
            &--mod {
                height: 50vh;
            }
        }
    }
}

@media screen and (min-height: 810px) {
    .map-page {
        &__map-container {
            &--mod {
                height: 45vh;
            }
        }
    }
}

@media screen and (min-height: 830px) {
    .map-page {
        &__map-container {
            &--mod {
                height: 54vh;
            }
        }
    }
}


@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

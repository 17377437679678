@import "../../../../../../shared/styles/styles.scss";

.work-profile{

    &__box-content {
        width: 100%;
        overflow-y: scroll;
        height: 100%;
        box-shadow: 0px 0px 12px $color-box-shadow;
        border-radius: 10px;
        opacity: 1;
        background-color: $color-white;
        padding: 20px;
        padding-bottom: 140px;

        &::-webkit-scrollbar{
            display: none;
        }
    }

    &__container{
        margin-top: 40px;
    }

    &__content{
        justify-content: space-between;
        width: 100%;
    }

    &__label{
        @include global-typography($color-meteorite,20px,18px,bold);

        &__cursor{
            cursor: pointer;
        }

    }

    &__upload-file{
        @include global-typography($color-error-rose,20px,18px,bold);
        margin-right: 3px;

        &__info{
            width: 210px;
            height: 31px;
            @include global-typography($color-amethyst-smoke,14px,13px,bold);
        }

        &__text{
            @include global-typography($color-royal-blue,18px,18px,500);
            margin-right: 5px;
        }
    }

    &__hidden{
        display: none;
    }
    &__services {

        &__info {
            height: 170px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 5px;
            border: 1px solid $color-blue-haze;

            &--mod{
                @extend .work-profile__services__info;
                border-color: $color-error-rose
            }
        };

        &__content-textarea {
            width: 95%;
            height: 90%;
            margin: 0px;
            margin-top: 8px;
            resize: none;
            border-radius: 5px;
            border: 0;
            box-shadow: none;
            background: $color-whisper;
            @include global-typography($color-amethyst-smoke,20px,18px,bold);

            &:focus{
                border: 0;
                box-shadow: none;
                background: $color-whisper;
            }

            &::placeholder{
                @include global-typography($color-amethyst-smoke,20px,18px,bold);
            }
        };

        &__circular-progress-bar {

            width: 11px;
            height: 11px;
            display: flex;
            margin-left: 3px;

            &__container{
                width: 93%;
                height: 20%;
                display: flex;
                align-items: center;
                justify-content: flex-end
            }

            &__content{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__label{
                margin-top: 17px;
                margin-bottom: 5px;
                width: 60%;
                @include global-typography($color-meteorite,20px,18px,bold);
            }

            &__progress {
                @include global-typography($color-blue-haze,20px,14px,500);
            }
        };
    };

    &__input.MuiFormControl-root {

        width: 100%;

        &>label {

            z-index: 1;

            &.MuiFormLabel-root {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -15px;
                left: -10px;
                &+div>div{
                    top: 15px;
                    right: 0;
                }
            }

            &.MuiInputLabel-shrink {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -5px;
                transform: translate(14px, -14px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 100%;

                &+div>div{
                    top: auto;
                }
            }

            &.Mui-disabled {
                color: $color-amethyst-smoke;
            }
        }


        &>div {

            &:hover {
                &>fieldset {
                    border-color: $color-amethyst-smoke;
                }
            }

            &.Mui-focused {
                &>fieldset {
                    border-bottom: 2px solid $color-blue-haze;
                }
            }

            &.Mui-error {
                &>input {
                    &+fieldset {
                        border-bottom: 1px solid $color-error-rose;
                    }
                }
            }

            &>input {
                padding: 0 0 0 4px;
                box-shadow: none;
                border: 0;
                background: transparent;
                cursor: text;
                @include global-typography($color-amethyst-smoke, 20px, 18px, bold);
                -webkit-text-fill-color: $color-amethyst-smoke;

                &:disabled {
                    background-color: transparent;
                }

                &+fieldset {
                    border: 0;
                    border-bottom: 1px solid $color-blue-haze;
                    border-radius: 0;
                }
            }

            &.Mui-disabled {
                background-color: $color-blue-haze;
            }

        }

    }

    &__input-ellipse.MuiFormControl-root {
        @extend .work-profile__input;

        &>label {
            &.MuiFormLabel-root {
                transform: translate(14px, 0px);
                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;
                max-width: 100%;
                max-height: 36px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            &.MuiInputLabel-shrink {
                top: 0;
                transform: translate(14px, -34px);
                white-space: normal;
                max-height: 36px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    &__input-autocomplete.MuiFormControl-root {
        @extend .work-profile__input;
        padding-left: 0;

        &>label {

            &.MuiFormLabel-root {
                left: -10px;
                top: 0;
            }
        }

        &>div{
            padding: 0;


            &>fieldset {
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid $color-blue-haze;
            }
        }

        &>.Mui-error {
            padding: 0;


            &>fieldset {
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid $color-error-rose;
            }
        }
    }

    &__input-autocomplete-ellipse.MuiFormControl-root {
        @extend .work-profile__input-autocomplete;

        &>label {
            &.MuiFormLabel-root {
                transform: translate(14px, 0px);
                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;
                max-width: 100%;
                max-height: 36px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            &.MuiInputLabel-shrink {
                top: 0;
                transform: translate(14px, -6px);
                white-space: normal;
                max-height: 36px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}

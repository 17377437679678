@import "../../../../../shared/styles/styles.scss";

.complete-profile {
    background: $color-whisper;
    padding: 10px;
    height: 100vh;

    &__header {
        width: 100%;
        height: 170px;
        box-shadow: 0px 0px 12px $color-box-shadow;
        border-radius: 10px;
        opacity: 1;
        background-color: $color-white;
        margin-bottom: 15px;
        padding: 20px 0 10px;

        &__line {
            height: 1px;
            background: $color-athens-gray;
            width: 100%;
        }

        &__box {
            padding: 0 20px 11px;
            justify-content: space-between;
        }

        &__save {
            width: 78px;
            height: 38px;
            @include global-typography($color-meteorite, 18px, 15px, bold);
            padding-right: 8px;
        }

        &__stepper {

            &#RFS-StepperContainer {
                padding: 15px 5px 12px;

                &>#RFS-StepContainer>#RFS-ConnectorContainer {
                    left: calc((-50% + 2em) - 16px);
                    right: calc((50% + 2em) - 16px);
                }

                &>#RFS-StepContainer>#RFS-StepMain {

                    &>#RFS-StepButton {
                        @include gradient-dull-royal;
                        box-shadow: 0px 3px 12px $color-box-shadow;
                        opacity: 1;

                        &:disabled {
                            background: $color-box-shadow;
                        }
                    }

                    &>#RFS-LabelContainer>#RFS-Label {
                        margin-top: 5px;
                    }
                }
            }

        }

        &__step+#RFS-LabelContainer {

            &>#RFS-Label {
                @include global-typography($color-meteorite, 16px, 14px, bold);
                max-width: 70px;
            }
        }

        &__step-inactive+#RFS-LabelContainer {

            &>#RFS-Label {
                @include global-typography($color-box-shadow, 16px, 14px, bold);
                max-width: 70px;
            }
        }
    }

    &__footer {
        width: 100%;
        height: calc(100% - 190px);
    }

    &__buttons {
        z-index: 2;
        position: fixed;
        bottom: 0;
        height: 120px;
        @include gradient-white-athens;
        width: 100%;
        left: 0px;
        justify-content: flex-start;
        align-items: center;


        &__next {
            @include global-button(42px, 85%);
            @include global-typography($color-athens-gray-light, 20px, 18px, bold);
            margin-bottom: 10px;
        }

        &__back {
            @include global-typography($color-amethyst-smoke, 18px, 18px, normal);
        }
    }
}
@import '../../../styles/styles.scss';

.loading-app{
    height: 100vh;
    background-color: $color-blue-haze;

    &__container{

        &__loading-spinner{
            width: 100%;
            height: 100%;
            animation: spinner 1.5s linear infinite;
        }
        &__text{
            padding-top: 20px;
        @include global-typography($color-meteorite,30px,30px,bold)
        }
    }

}
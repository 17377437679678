@import '../../../shared/styles/styles.scss';

.my-network-page {

    &__sidebar-width{
        width: 0px;
        transition: width 0.5s  ease-in-out;

        .view-profile__container,
        .view-profile__footer,
        .contact-card__box,
        .contact-card__box--isDesktop{
            display: none;
        }
    }

    &__container {
        background-color: $color-whisper;
        justify-content: space-between;
        padding: 10px 25px 20px;
    }

    &__header {
        &__content {
            width: 99%;
            text-align: center;
            margin-left: -16px;
        }

        &__icon {
            color: $dark-gray;
            margin-right: 10px;
        }

        &__text {
            font-size: 12px;
            color: $dark-gray;
        }
    }

    &__content {
        justify-content: space-between;
    }

    &__user {

        &__icon {
            height: 65px;
            width: 65px;
            margin-right: 10px;
            box-shadow: 0px 0px 12px $color-cadet-blue;
            border-radius: 100%;

            &--mod{
                color: #EEEEEE;
                height: 50px;
                width: 50px;
            }
        }

        &__name {
            @include global-typography($color-meteorite,24px,20px,600);
            margin: 0;
            max-width: 10rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__position {
            @include global-typography($color-royal-blue,12px,14px,bold);
            margin: 0;
            display: flex
        }
    }

    &__members {
        @include global-typography($color-meteorite,18px,14px,normal);

        &__content {
            justify-content: center;
        }

        &__num {
            @include global-typography($color-meteorite,27px,22px,600);
            text-align: right;
        }

        &__members-total {
            display: flex;
            vertical-align: middle;
            justify-content: flex-end;
            align-items: center;
        }

        &__icon-info {
            height: 20px;
            margin-right: 2px;
        }
    }

    &__info {
        background: $white;
        border-radius: 10px;
        margin-top: 10px;
        height: 68px;
        width: 50%;

        &__content {
            height: 40px;

            &__box {
                border-right: 2px solid #ECECEC;
            }

            &__types-network {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__icon-info {
                height: 17px;
                margin-right: 2px;
            }
        }

        &__box {
            @include global-typography($color-meteorite,24px,20px,600);
            height: 20px;
            margin-top: -4px;
        }

        &__text {
            @include global-typography($color-meteorite,23px,18px,bold);
        }
    }

    &__add-user {
        
        &__container {
            margin-top: 5%;
            margin-bottom: 5%;
            height: 40px;
            display: flex;
            direction: rtl;
        }

        &__icon {
            width: 40px;
            margin-right: -1rem;

            &--last {
                width: 40px;
            }
        }

        &__arrow {
            color: #707070;
            margin-top: 5%;
        }

        &__text {
            font-size: 12px;
            color: $color-meteorite;
            max-width: 135px;
            line-height: 15px;

            &__mod {
                max-width: none;
                line-height: normal;
                font-weight: bold;
            }
        }
    }

    &__back-button {
        z-index: 10;
    }

    &__select-container {
        display: flex;
        padding: 1rem 1rem;
        width: 420px;
        border-radius: 12px;
        color: #bbbbbb;
        border-style: dashed;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 4rem;

        &--mod {
            margin-top: 0px;
            height: fit-content;
        }

        &--text {
            color: $color-amethyst-smoke;
            font-size: 12px;
            margin: 0;
            line-height: 1;
        }
    }

    &__sidebar{

        width: calc(100% - 420px);
        transition: width 0.5s ease-in-out;
        height: 92vh;
        overflow-y: scroll;

        &::-webkit-scrollbar{
            display: none;
        }

        &__target{
            width: 420px;
            transition: width 0.5s ease-in;
            height: 93vh;
            overflow-y: scroll;
            box-shadow: -12px 0px 12px #00000010;
            &::-webkit-scrollbar{
                display: none;
            }

            &__container-title {
                background-color: $color-whisper;
                padding: 20px;
                width: 100%;
                align-items: center;
                @include global-typography($color-meteorite, 18px, 18px, bold);
            }
            
            &__title {
                width: 90%;
                justify-content: center;
                align-items: center;

            }

            &__icon-network {
                margin-right: 10px;
            }

            &__button-go-back {
                transform: rotate(90deg);
            }

            &--mod{
                width: 100%;
                overflow-x: hidden;
                transition: width 0.5s ease-in;
            }
        }

    }

    &__hide{
        display: none;
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small) {
    .my-network-page {
    
        &__add-user {

            &__container {
                margin-top: 25%;
            }

            &__arrow {
                margin-top: 10%;
            }
        }

        &__info {
            width: 100%;
        }

        &__select-container {
            width: 92%;
        }

        &__members-container {
            width: 92%;


            &__details {

                &__number-votes {
                    width: 15px;
                    height: 15px;
                    font-size: 0.6rem;
                }
            }

        }
    }
}

//Medium
@media screen and (min-width: $media-query-medium) {
    .my-network-page {
        &__container {
            &--mod{
                display: none;
            }
        }

        &__add-user {
            &__content {
                height: 735px;
            }
        }
    }
}
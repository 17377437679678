@import "../../../../styles/styles.scss";

.navbar {

    &.top-bar {
        padding: 0.5rem 10px 0.5rem 30px;
        position: sticky;
        top: 0;
        position: -webkit-sticky;
        background-color: $color-whisper;

        &--mod{
            z-index: 2;
            height: 100px;
        }
    }

    &__title {
        color: #707070;
        font-weight: bold;
        font-size: small;
        margin-left: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__img-logo {
            height: 30px;
        }

        &__content{
            width: 160px;
        }
    }

    &__user {

        &__name {
            color: #707070;
            font-weight: bold;
            font-size: 13px;
            text-align: right;
            width: 145px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__cargo {
            color: #707070;
            font-size: 10px;
            text-align: right;
        }

        &__logo {
            width: 40px;
            justify-content: flex-end;
            color: #B9B9B9;
        }

        &__img {
            width: 30px;
            height: 30px;
        }
    }

    &__button {
        color: #707070;
        transition: none;

        &--active {
            border-bottom: 4px solid #BBBBBB;
            margin-bottom: -10px;
            border-bottom: 5px solid transparent;
            @include gradient-royal-blue;

            label {
                @include global-typography($color-meteorite,26px,20px,bold);
            }
        }

        &__text {
            @include global-typography($color-amethyst-smoke,26px,20px,normal);
            cursor: pointer;
        }
    }
}

//media queries

//Small
@media screen and (max-width: $media-query-small) {
    .navbar {
        &.top-bar {
            display: block;
            padding: 0.5rem 0;
        }

        &__title {
            &__content {
                margin-bottom: 10px;
                width: auto;
            }
        }

        &__user {
            display: none;
        }

        &__link {
            justify-content: space-evenly;
        }

        &__button {
            background-color: $white;
            width: calc(100%/3);
            text-align: center;
            margin-bottom: -10px;
        }
    }
}

//Medium
@media screen and (min-width: $media-query-medium) {
    .navbar {

        &__button {
            width: 95px;
            text-align: center;
        }
    }
}
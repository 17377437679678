@import "./../../../styles/styles.scss";

.view-profile-skeleton {
    height: 100vh;
    padding-top: 12px;
    background-color: $color-whisper;

    &__content {
        padding: 10px 30px 0 30px;
        width: 100%;
        height: 67%;
        
        &-mod{
            background-color: $color-whisper;
        }
        &-active{
            padding: 0;
            width: 100%;
        }
        &-circle{
        width: 35px;
        height: 35px;
        background-color: $color-whisper;
        border-radius: 25px;
        -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
        animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;

            &__mod{
                width: 100px;
                height: 100px;
                background-color: $color-whisper;
                border-radius: 50px;
                -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
            }
            &-inactive{
                width: 35px;
                height: 35px;
                background-color: $color-white;
                border-radius: 25px;
                -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
            }
        }
    }
    &__footer{
            background-color: $color-white;
            height: 48%;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            margin: 0px 10px 0px 10px;
            box-shadow: 0px 0px 12px rgb(199 198 218 / 53%);
            margin-bottom: 15%;
    }

    &__member {

        &__header {
            height: 296px;
            padding: 12px;
            border-radius: 10px;
            margin: 0px 10px 10px 10px;
            background-color: $color-white;
            box-shadow: 0px 0px 12px rgb(199 198 218 / 53%);
        }

        &__button {
            width: 90px;

            &__text{
                display: block;
                background-color: $color-whisper;
                width: 40%;
                height: 50px;
                margin: 10px 0 10px;
                padding: 10px 0 10px 0;
                transform-origin: 0 55%;
                border-radius: 9px/6.7px;
                -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;

                &-mod{
                    width: 60%;
                    height: 30px;
                    display: block;
                    background-color: $color-whisper;
                    margin: 10px 0 10px;
                    padding: 10px 0 10px 0;
                    transform-origin: 0 55%;
                    border-radius: 9px/6.7px;
                    -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                    animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                }
                &-subtitle{
                    display: block;
                    background-color: $color-whisper;
                    width: 72%;
                    height: 25px;
                    margin: 10px 0 0;
                    transform-origin: 0 55%;
                    border-radius: 9px/6.7px;
                    -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                    animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;

                    &-inactive{
                        display: block;
                        background-color: $color-white;
                        width: 72%;
                        height: 25px;
                        margin: 10px 0 0;
                        transform-origin: 0 55%;
                        border-radius: 9px/6.7px;
                        -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                        animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                    }
                }
            }
        }
    }
}


//media queries
//large

@media screen and (min-width: $media-query-medium) {

    .box-member-skeleton {

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            
        }

        &__text {
            display: none;
        }

        &__line {
            background-color: $color-whisper;
            height: 40px;
            width: 3px;

            &__circle {
                height: 10px;
                width: 10px;
                border: 2px solid #B9B9B9;
                border-radius: 100%;
            }
        }
    }
}

//media queries
//small
@media screen and (max-width: $media-query-small) {
    .box-member-skeleton {
        &__delete-modal {
            height: 50%;
            width: 100%;
        }

        &__number-member {
            width: 15px;
            height: 15px;
            font-size: 0.6rem;

            &--mod {
                font-size: 0.4rem;
            }
        }
    }
}

@import "../../../../../../styles/styles.scss";

.menu {
    &__container {
        position: fixed;
        min-width: 20rem;
        width: 100%;
        height: 20rem;
        background: $white;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        &__overlay {
            background-color: rgba(106, 106, 106, 0.368627451);
            opacity: 1;
            position: fixed;
            bottom: 0;
            width: 100%;
            height: 100%;
            left: 0;
            z-index: -1;
        }

        &__menu-content {
            margin-top: 3%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: baseline;
        }

    }

    &__header {

        &__container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: baseline;
            flex-direction: row;
            color: $color-royal-blue;
        }

        &__text {
            color: $color-meteorite;
            font-size: 20px;
            margin-left: 5px;
            font-weight: bold;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        &__container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0 0.9rem 0 2rem;
        }

        &__containers {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            &__border-bottom {
                border-bottom: #EEEEEE;
                border-bottom-width: 1px;
                border-bottom-style: solid;
            }

            &__chevron-icon {
                padding: 0 0 0 26px;
            }

            &__icon {
                height: 26px;
                stroke: white;

                &__container {
                    height: 26x;
                    background-color: $color-royal-blue;
                    border-radius: 35px;
                    padding-left: 5px;
                    max-width: 26.5px;

                    &--logout {
                        height: 30px;
                        background-color: #B9B9B9;
                        border-radius: 35px;
                    }
                }

                &--mod {
                    fill: white;
                }
            }

            &__text {
                color: $color-meteorite;
                font-size: 15px;
                height: 18px;
                margin: 0;

                &--tittle {
                    font-weight: bold;
                }

                &--logout {
                    padding-left: 1rem;
                }

                &--mod {
                    font-size: 12px;
                }
            }

            &__text-container {
                display: flex;
                flex-direction: column;
                padding-left: 1rem;
                &--mod{
                    height: 50px;
                }
            }

        }
    }

}

//media query

//large
@media screen and (min-width: $media-query-medium) {
    .menu {
        &__container {
            width: 50%;
            bottom: 5.5rem;
            &__overlay {
                display: flex;
                justify-content: center;
            }
        }
    }
}

//small
@media screen and (max-width: $media-query-small) {
    .menu { 
        &__container {
            bottom: 3rem;
        }
    }
}

//landscape
@media screen and (max-height: $media-query-small) and (orientation: landscape) {
    .menu {
        &__container {
            bottom: 4rem;
            height: 18rem;
        }
    }
}
@import "../../../../../shared/styles/styles.scss";


.user-invitation-setting {

    &__body{
        overflow: hidden;
    }

    &__content {
        position: fixed;
        bottom: 0;
        max-height: 100%;
        background: $color-white;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        &--mod {
            height: 50%;
            position: relative;
            border-radius: 10px;
        }

        &__overlay {
            z-index: 2;
            @include global-modal-overlay;
            opacity: 1;
            position: fixed;
            bottom: 0;
            width: 100%;
            height: 100%;

            &--mod{
                align-items: center;
            }
        }
    }

    &__container {
        padding: 1rem 2rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--mod {
            padding: 1rem
        }

        &__content{
            margin-bottom: 18px;
        }

        &__title {
            @include global-typography($color-meteorite,20px,17px,bold);
        }

        &__button-close{
            height: 13px;
            width: 13px;
        }
    }

    &__form {
        height: 90%;
        justify-content: space-around;

        &>.css-viou3o-MuiAutocomplete-root {
            width: 100%;
        }

        &__content{
            max-height: 70vh;
            overflow-y: scroll;

            &::-webkit-scrollbar{
                display: none;
            }
        }
    }
    
    &__document {
        width: 100%;
        margin-top: 20px;
        justify-content: space-between;

        &__select {
    
            &__content {
                width: 23%;
                display: flex;
                align-items: flex-end;
            }

            &.MuiInputBase-root {
            
                width: 100%;
                height: 26px;
                margin-bottom: 7%;
        
                &>div.MuiSelect-select{
        
                    padding: 0;
                    padding-top: 10px;
                    height: 30px;
                    @include global-typography($color-meteorite,20px,18px,bold);
                }
        
                &>fieldset,
                &.Mui-focused>fieldset{
                    border: 0;
                    border-bottom: 1px solid $color-blue-haze;
                    border-radius: 0;
                }
        
            }
            &__options.MuiMenuItem-root{
                @include global-typography($color-meteorite,23px,18px,bold);
                border-bottom: 1px solid $color-cadet-blue;
                margin: 0 10px;
                background: $color-whisper;
            }

            &__icon.css-3qbkez-MuiSelect-icon,
            &__icon.css-10q54uo-MuiSelect-icon{
                position: absolute;
                right: 0px;
                top: 8px;
            }

        }
    }

    &__input.MuiFormControl-root {

        width: 100%;
        margin-top: 30px;

        &>label {

            &.MuiFormLabel-root {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -14px;
                left: -13px;
                z-index: 0;

                &+div>div{
                    top: 10px;
                    right: 2px;
                }
            }

            &.MuiInputLabel-shrink {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -5px;
                transform: translate(14px, -14px);


                &+div>div{
                    top: 9px;
                }
            
                
            }

            &.Mui-disabled{
                color: $color-amethyst-smoke;
                z-index: 1;
            }
        }


        &>div {

            &:hover {
                &>fieldset {
                    border-color: $color-amethyst-smoke;
                }
            }

            &.Mui-focused {
                &>fieldset {
                    border-bottom: 2px solid $color-blue-haze;
                }
            }

            &.Mui-error {
                &>input {
                    &+fieldset {
                        border-bottom: 1px solid $color-error-rose;
                    }
                }
            }

            &>input {
                padding: 0;
                box-shadow: none;
                border: 0;
                background: transparent;
                cursor: text;
                @include global-typography($color-amethyst-smoke, 20px, 18px, bold);
                -webkit-text-fill-color: $color-amethyst-smoke;

                &:disabled {
                    background-color: transparent;
                }

                &+fieldset {
                    border: 0;
                    border-bottom: 1px solid $color-blue-haze;
                    border-radius: 0;
                }
            }

            &.Mui-disabled {
                background-color: $color-blue-haze;
            }

        }

    }

    &__input-select{

        &.MuiFormControl-root {
            @extend .user-invitation-setting__input;
            width: 75%;
            padding: 0;
            margin-top: 0;
    
            &>label {
    
                &.MuiFormLabel-root {
                    transition: all 0.5s;
                    left: 0px;
                }
    
                &.MuiInputLabel-shrink {
                    transition: all 0.5s;
                    left: -38%;
                }
            }
        }

        &__error-text{
            width: 100%;
            height: 10px;
            margin: 6px 0px;
            @include global-typography( $color-error-rose, 13px, 12px, normal);
        }
    }

    &__input-autocomplete.MuiFormControl-root {
        @extend .user-invitation-setting__input;
        padding-left: 0;
        margin-top: 25px;

        &>label {

            &.MuiFormLabel-root {
                top: -2px;
            }
        }

        &>div,
        >.Mui-error {
            padding: 0;


            &>fieldset {
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid $color-error-rose;
            }
        }

        &>div{

            &>input.MuiAutocomplete-input{
                padding: 7.5px 4px 0px 0px;
            }

            &>fieldset {
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid $color-blue-haze;
            }

        }

    }

    &__input-phone {

        &__content {
            justify-content: space-between;


            &>div.MuiFormControl-root {
                width: 75%;
            }
        }

        &.react-tel-input {
            width: 10%;
            display: flex;
            align-items: flex-end;

            &>div.flag-dropdown {
                background: transparent;
                border: 0;
                width: 68px;

                &>div.selected-flag {
                    background: transparent;
                    top: -5px;
                    left: 13px;

                    &>div.flag {
                        background: transparent;
                        margin-top: 8px;

                        &>div.arrow {
                            top:0;
                            height: 10px;
                            width: 22px;
                            border-bottom-left-radius: 100%;
                            border-bottom-right-radius: 100%;
                            left: 26px;
                            border :0;
                            background-image: url("../../../../../assets/home/complete-registration/icon_dropdown_arrow.svg");
                            &.up{
                                top:0;
                                height: 10px;
                                width: 22px;
                                border-bottom-left-radius: 100%;
                                border-bottom-right-radius: 100%;
                                left: 26px;
                                border :0;
                                background-image: url("../../../../../assets/home/complete-registration/icon_dropdown_arrow.svg");
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            &>input.form-control {
                @include global-typography($color-amethyst-smoke, 18px, 18px, bold);
                box-shadow: none;
                padding-left: 4px;
                width: 68px;
                border: 0;
                border-bottom: 1px solid $color-blue-haze;
                border-radius: 0;
                padding-top: 22px;
                padding-bottom: 13px;

                &::placeholder {
                    color: transparent;
                }
            }
        }
    }

    &__button {

        &[type=submit] {
            @include global-button(42px, 100%);
            margin: 30% 0 0;
            border-radius: 10px;
            padding: 10px;
            @include global-typography($color-athens-gray-light, 20px, 18px, bold);
        }

        &__back {
            margin-top: 4%;
            padding-bottom: 10%;

            &__text {
                @include global-typography($color-meteorite, 18px, 18px, normal);
            }
        }

    }
    
    &__modal-success {
        height: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        &__user {
            @include global-typography($color-meteorite, 20px, 24px, bold);
        }

        &__icon {
            height: 100px;
            width: 100px;
        }

        &__description {
            text-align: center;
            @include global-typography($color-meteorite, 20px, 20px, bold);
        }

        &__button-close {
            @include global-button(42px, 100%);
            margin: 12% 0 0;
            border-radius: 10px;
            padding: 10px;
            @include global-typography($color-athens-gray-light, 20px, 18px, bold);
        }
    }
}

// media queries

// Large
@media screen and (min-width: $media-query-medium) {
    .user-invitation-setting {
        &__content {
            max-width: 410px;

            &__overlay {
                display: flex;
                justify-content: center;
            }
        }
    }
}

// Small
@media screen and (max-width: $media-query-small) {
    .user-invitation-setting {
        &__content {
            width: 95%;
        }
    }
}

// Landscape
@media screen and (orientation: landscape) {
    .user-invitation-setting {
        &__form{
            &__content {
                overflow: scroll;
                max-height: 70vh;
                height: auto;

                &::-webkit-scrollbar{
                   display: none;
                }
            }
        }
    }
}
@import '../../../shared/styles/styles.scss';

.reports {
    width: 100%;
    padding: 40px 4% 40px 4%;
    background-color: $color-whisper;
    overflow-y: scroll;

    &__network-title {
        text-align: center;
        margin-bottom: 15px;
        @include global-typography($color-meteorite, 24px, 21px, 600);
    }
    &__network-line {
        border-bottom: 3px solid $color-royal-blue;
        width: 9rem;
        margin-bottom: 30px;
    }

    &__barChart-container {
        width: 100%;
        height: 500px;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0px 0px 12px 1px $color-box-shadow;
        padding: 4%;
        margin-bottom: 40px;

        &__header {
            height: 13%;
            padding: 2% 0px;
            border-bottom: 1px solid $color-athens-gray;
            justify-content: space-between;

            &__title {
                @include global-typography($color-meteorite, 23px, 18px, bold);
            }

            &__subtitle {
                @include global-typography($color-amethyst-smoke, 21px, 16px, bold);
            }
            
            &__button{
                @include global-button(31px, 102px);
                @include global-typography($white, 20px, 15px, bold);
            }
        }

        &__loading{
            margin-bottom: 10%;
            margin-top: 10%;
            animation: spinner 1.5s linear infinite;
        }
        
        &__preview{
            width: 98%;
            margin-bottom: 10%;
            padding-bottom: 10%;
            border-bottom: 1px solid $color-meteorite;
            text-align: center;

            
            &__title{
                @include global-typography($color-blue-haze, 18px, 18px, bold);
            }

            &__description{
                width: 90%;
                @include global-typography($color-blue-haze, 15px, 15px, 500);
            }
        }

        &__barChart {
            height: 67%;
            padding: 3%;

            &__range {
                &__label {
                    padding-left: 10%;
                    @include global-typography($color-meteorite, 21px, 16px, bold);
                }

                &__select {
                    margin: 0px 5px;

                    &>div{
                        min-height: 25px;
                        border: 0px;
                        border-radius: 0;
                        border-bottom: 1px solid $color-blue-haze;
                        box-shadow: none;

                        &:hover,
                        :focus {
                            border-color: $color-blue-haze;
                            box-shadow: none;
                        }

                        &>.css-319lph-ValueContainer {
                            padding: 2px 0px;

                            >.css-qc6sy-singleValue {
                                @include global-typography($color-meteorite, 21px, 16px, bold);
                            }
                        }

                        &>div{
                            padding: 0px;

                            &>div{
                                margin: 0;
                            }
                        }

                        & div:nth-child(2){
                            padding: 0px;
                            width: 35%;

                            &>span{
                                width: 0px;
                            }

                            &>div{
                                padding: 4px;
                                width: auto;
                            }
                        }
                    }

                    &>.css-26l3qy-menu {
                        &>.css-4ljt47-MenuList {
                            max-height: 188px;
                            padding: 0;

                            &>.css-1n7v3ny-option {
                                @include global-typography($white, 21px, 16px, bold);
                                background-color: $color-amethyst-smoke
                            }

                            &>.css-yt9ioa-option {
                                @include global-typography($color-meteorite, 21px, 16px, bold);
                            }

                            &>.css-9gakcf-option {
                                @include global-typography($white, 21px, 16px, bold);
                                background-color: $color-royal-blue;
                            }
                        }
                    }
                }
            }
        }

        &__counters {
            height: 20%;
            align-items: flex-end;
            padding: 0px 10px 10px 10px;

            &__members {
                &__count {
                    @include global-typography($color-meteorite, 51px, 40px, bold);
                    transition: color .5s;
                    
                    &--disabled{
                        @extend .reports__barChart-container__counters__members__count;
                        color: $color-blue-haze;
                    }
                }
                
                &__label {
                    @include global-typography($color-amethyst-smoke, 14px, 14px, bold);
                    padding-right: 10%;
                    transition: color .5s;
                    
                    &--disabled{
                        @extend .reports__barChart-container__counters__members__label;
                        color: $color-blue-haze;
                    }
                }
            }
            
            &__defections {
                &__count {
                    @include global-typography($color-error-rose, 51px, 40px, bold);
                    transition: color .5s;
                    
                    &--disabled{
                        @extend .reports__barChart-container__counters__members__count;
                        color: $color-blue-haze;
                    }
                }
                
                &__label {
                    @include global-typography($color-error-rose, 14px, 14px, bold);
                    padding-right: 10%;
                    transition: color .5s;
                    
                    &--disabled{
                        @extend .reports__barChart-container__counters__members__label;
                        color: $color-blue-haze;
                    }
                }
            }
        }
    }

    &__summary {
        width: 100%;
        margin-bottom: 35px;

        &__totalMembers {
            display: flex;
            align-items: flex-end;
            margin: 8px 0px;
            @include global-typography($color-meteorite, 27px, 22px, 600);
        }

        &__card {
            width: 100%;
            height: 76px;
            margin-top: 10px;
            padding: 6% 0px;
            border-radius: 10px;
            border: 1px solid $color-blue-haze;

            &__content {
                height: 40px;

                &__box {
                    border-right: 2px solid $color-blue-haze;
                }

                &__types-network {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &__icon-info {
                    height: 17px;
                    margin-right: 2px;
                }
            }

            &__box {
                @include global-typography($color-meteorite, 24px, 20px, 600);
                height: 20px;
                margin-top: -4px;
            }

            &__text {
                @include global-typography($color-meteorite, 23px, 18px, bold);
            }
        }
    }

    &__pieChart-container {
        height: 240px;
        width: 100%;
        margin-bottom: 40px;

        &__header {
            height: 60px;
            width: 95%;
            margin-bottom: 30px;

            &__title-container {
                width: 75%;

                &__title {
                    @include global-typography($color-meteorite, 23px, 18px, bold)
                }

                &__description {
                    margin-top: 10px;
                    @include global-typography($color-amethyst-smoke, 12px, 12px, 500)
                }
            }

            &__button-container {
                width: 25%;
                padding-left: 2%;

                &__download {
                    &__text {
                        margin-left: 5px;
                        @include global-typography($color-meteorite, 15px, 12px, 500)
                    }

                    &--disabled{
                        pointer-events: none;
                        cursor: default;
                    }
                }
            }

        }

        &__card {
            width: 97%;
            border-radius: 10px;
            border: 1px solid $color-blue-haze;
            padding: 5%;
            

            &__pie-chart {
                width: 40%;
                height: 100%;
                padding-top: 5px;
            }

            &__analytics {
                width: 60%;
                height: 100%;

                &__header {
                    display: flex;
                    flex-direction: row-reverse;
                    height: 20px;
                    margin-bottom: 5px;

                    &__title {
                        width: 70%;
                        text-align: end;
                        @include global-typography($color-meteorite, 9px, 9px, bold);
                    }
                }

                &__statistics {
                    width: 100%;
                    height: 90px;

                    &__item {
                        width: 100%;
                        justify-content: space-between;

                        &__icon {
                            width: 12px;
                            height: 12px;
                            border-radius: 100%;
                        }

                        &__text {
                            margin-left: 8px;
                            @include global-typography($color-meteorite, 16px, 12px, bold);
                        }

                        &__percentage {
                            @include global-typography($color-amethyst-smoke, 18px, 14px, bold);
                        }
                    }
                }
            }
        }
    }

    &__general-network {
        width: 100%;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0px 0px 12px 1px $color-box-shadow;
        padding: 4%;
        margin-bottom: 40px;
    }
}
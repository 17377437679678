@import "../../../../../../../shared//styles/styles.scss";

.members-information {

    &__icon-container {
        width: 100px;
        margin-left: 25px;

        &__img {
            width: 75px;
        }

        &__icon {
            margin-left: 45%;
            margin-top: -30%;
            width: 30px;
            height: 30px;
            z-index: 1;
        }

        &__text {
            @include global-typography($color-meteorite, 28px, 22px, bold);
        }
    }

    &__summary {
        height: 85%;
        border: 1px solid $color-athens-gray;
        border-radius: 14px;
        padding: 3%;
        margin-top: 10px;
        overflow-y: scroll;
        position: relative;
        display: relative;

        &__member {
            width: 100%;
            min-height: 85px;
            padding: 7px 0px 7px 0px;
            border-bottom: 2px solid $color-athens-gray;

            &__icon-container {
                height: 100%;
                width: 25%;
                text-align: center;
                margin: auto;

                &__icon{
                    height: 50px;
                    width: 100%;
                    position: relative;

                    &__img{
                        height: 100%;

                        &--inactive{
                            height: 100%;
                            filter: grayscale(100%);
                        }
                        &--pending{
                            height: 100%;
                            filter: opacity(0.6) ;
                        }
                    }
                    
                    &__icon{
                        width: 23px;
                        height: 23px;
                        position: absolute;
                        bottom: 0px;
                        right: 10px;
                    }

                    &__count{
                        width: 23px;
                        height: 23px;
                        position: absolute;
                        background-color: $color-royal-blue;
                        border-radius: 100%;
                        bottom: 0px;
                        right: 10px;
                        @include global-typography($white, 20px, 13px, 600);
                    }

                    &__text{
                        @include global-typography($color-meteorite, 13px, 11px, bold);

                        &--mod{
                            @include global-typography($color-amethyst-smoke, 13px, 11px, bold);
                        }
                    }
                }
            }

            &__info {
                height: 100%;
                width: 75%;
                padding-left: 5px;

                &__type {
                    @include global-typography($color-meteorite, 18px, 14px, bold);
                }

                &__description {
                    margin-top: 5px;
                    &__paragraph{
                        @include global-typography($color-amethyst-smoke, 12px, 12px);
                        margin-bottom: 6px;
                    }
                }
            }

        }

        :last-child {
            border-width: 0px;
        }
    }

    &__description {
        height: 85%;
        border: 1px solid $color-athens-gray;
        border-radius: 14px;
        padding: 3%;
        margin-top: 10px;
        overflow-y: scroll;
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }

        &__text-description {
            @include global-typography($color-amethyst-smoke, 14px, 16px, 500)
        }
    }
}
@import "../../../../../../shared/styles/styles.scss";

.personal-data {

    &__box-content {
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        box-shadow: 0px 0px 12px $color-box-shadow;
        border-radius: 10px;
        opacity: 1;
        background-color: $color-white;
        padding: 20px;
        padding-bottom: 140px;
        &::-webkit-scrollbar{
            display: none;
        }
    }

    &__content {
        width: calc(100% - 100px);
        justify-content: space-around;
        margin: 5px 0 -21px;
    }

    &__content-photo {
        width: 100px;
    }

    &__icon {
        width: 91px;
        height: 91px;
        border-radius: 100%;

        &__error{
            @extend .personal-data__icon;
            border: 1px solid $color-error-rose;
        }
    }

    &__input.MuiFormControl-root {

        width: 100%;
        padding-left: 25px;

        &>label {

            z-index: 0;

            &.MuiFormLabel-root {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -15px;
                left: 15px;

                &+div>div{
                    top: 15px;
                    right: 2px;
                }
            }

            &.MuiInputLabel-shrink {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -5px;
                transform: translate(14px, -14px);
                width: 100%;

                &+div>div{
                    top: auto;
                }
            
                
            }
        }


        &>div {

            &:hover {
                &>fieldset {
                    border-color: $color-amethyst-smoke;
                }
            }

            &.Mui-focused {
                &>fieldset {
                    border-bottom: 2px solid $color-blue-haze;
                }
            }

            &.Mui-error {
                &>input {
                    &+fieldset {
                        border-bottom: 1px solid $color-error-rose;
                    }
                }
            }

            &>input {
                padding: 0 0 0 4px;
                box-shadow: none;
                border: 0;
                background: transparent;
                cursor: text;
                @include global-typography($color-amethyst-smoke, 20px, 18px, bold);
                -webkit-text-fill-color: $color-amethyst-smoke;

                &:disabled {
                    background-color: transparent;
                }

                &+fieldset {
                    border: 0;
                    border-bottom: 1px solid $color-blue-haze;
                    border-radius: 0;
                }
            }

            &.Mui-disabled {
                background-color: $color-blue-haze;
                height: 37px;
            }

        }

    }

    &__container {
        padding-top: 60px
    }

    &__text {
        @include global-typography($color-meteorite, 16px, 16px, bold);
        margin-top: 10px;
        cursor: pointer;

        &__error{
            @extend .personal-data__text;
            color: $color-error-rose;
            font-size: 16px;
        }
    }

    &__input-date.MuiFormControl-root {
        @extend .personal-data__input;
        padding-left: 0;

        &>label {

            &.MuiFormLabel-root {
                left: -10px;
            }
        }
    }

    &__input-autocomplete.MuiFormControl-root {
        @extend .personal-data__input;
        padding-left: 0;

        &>label {

            &.MuiFormLabel-root {
                left: -10px;
                top: 0;
            }
        }

        &>div{
            padding: 0;


            &>fieldset {
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid $color-blue-haze;
            }
        }

        &>.Mui-error {
            padding: 0;
            &>fieldset {
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid $color-error-rose;
            }
        }

    }
    
    &__input-autocomplete-ellipse.MuiFormControl-root {
        @extend .personal-data__input-autocomplete;
        &>label {
            max-width: 100%;
            max-height: 36px;
            overflow: hidden;
            transform: translate(14px, 0px);
            white-space: normal;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            &.MuiInputLabel-shrink {
                max-width: 100%;
                max-height: 36px;
                overflow: hidden;
                transform: translate(14px, -26px);
                white-space: normal;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    };

    &__input-select.MuiFormControl-root {
        @extend .personal-data__input;
        width: 75%;
        padding: 0;

        &>label {

            &.MuiFormLabel-root {
                transition: all 0.5s;
                left: 0px;
            }

            &.MuiInputLabel-shrink {
                transition: all 0.5s;
                left: -37%;
            }
        }
    }

    &__box {
        width: 100%;
        margin-top: 40px;
        justify-content: space-between;
    }

    &__select {

        &__content {
            width: 23%;
            display: flex;
            align-items: flex-end;
            background: $color-blue-haze;
            border-radius: 4px;
        }

        &.MuiInputBase-root {
            
            width: 100%;
            height: 26px;
    
            &>div.MuiSelect-select{
                padding: 0;
                padding-left: 5px;
                height: 30px;
                @include global-typography($color-amethyst-smoke,20px,18px,bold);
            }
    
            &>fieldset,
            &.Mui-focused>fieldset{
                border: 0;
                border-bottom: 1px solid $color-blue-haze;
                border-radius: 0;
            }

            &>img.personal-data__select__icon{
                z-index: 0;
            }

            &.Mui-disabled{
                width: 100%;
                height: 26px;
    
                &>div.MuiSelect-select{
                    padding: 0;
                    padding-left: 5px;
                    height: 30px;
                    @include global-typography($color-amethyst-smoke,20px,18px,bold);
                }
            
                &>fieldset,
                &.Mui-focused>fieldset{
                    border: 0;
                    border-bottom: 1px solid $color-blue-haze;
                    border-radius: 0;
                }

                &>img.personal-data__select__icon{
                    z-index: -1;
                }
            }
    
        }

        &__icon.css-3qbkez-MuiSelect-icon,
        &__icon.css-10q54uo-MuiSelect-icon{
            position: absolute;
            right: 0px;
            top: 5px;
        }

        &__options.MuiMenuItem-root{
            @include global-typography($color-meteorite,23px,18px,bold);
            border-bottom: 1px solid $color-cadet-blue;
            margin: 0 10px;
            background: $color-whisper;
            
        }

    }

    &__input-phone {

        &__content {
            justify-content: space-between;


            &>div.MuiFormControl-root {
                width: 80%;
            }
        }

        &.react-tel-input {
            margin-bottom: 10px;
            width: 10%;
            display: flex;
            align-items: flex-end;

            &>div.flag-dropdown {
                background: transparent;
                border: 0;
                width: 68px;

                &>div.selected-flag {
                    background: transparent;
                    top: -5px;
                    left: 13px;

                    &>div.flag {
                        background: transparent;
                        margin-top: 8px;

                        &>div.arrow {
                            top:auto;
                            height: 10px;
                            width: 22px;
                            border-bottom-left-radius: 100%;
                            border-bottom-right-radius: 100%;
                            left: 26px;
                            border :0;
                            background-image: url("../../../../../../assets/home/complete-registration/icon_dropdown_arrow.svg");
                            &.up{
                                top:auto;
                                height: 10px;
                                width: 22px;
                                border-bottom-left-radius: 100%;
                                border-bottom-right-radius: 100%;
                                left: 26px;
                                border :0;
                                background-image: url("../../../../../../assets/home/complete-registration/icon_dropdown_arrow.svg");
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            &>input.form-control {
                @include global-typography($color-amethyst-smoke, 18px, 18px, bold);
                box-shadow: none;
                padding-left: 4px;
                width: 68px;
                border: 0;
                border-bottom: 1px solid $color-blue-haze;
                border-radius: 0;
                padding-top: 22px;
                padding-bottom: 13px;

                &::placeholder {
                    color: transparent;
                }
            }
        }
    }

    &__hidden{
        display: none;
    }

}
@import '../../../../styles/styles.scss';

.tab-bar {
    position: fixed;
    bottom: 5%;
    width: 50%;
    left: 25%;

    &__container {
        display: flex;
        height: 48px;
        background: $color-whisper;
        justify-content: space-around;
        border-radius: 5px;
    }
    
    &__icon {

        color: $color-amethyst-smoke;

        &__container {
            position: relative;
            z-index: 1;
        }
        
        &__contain{
            position: relative;
            @include global-typography($color-amethyst-smoke,10px,10px,bold);
            line-height: 12px;

            &__active{
            @include global-typography($color-royal-blue,10px,10px,bold);
            padding: 11px 0 8px 0;
            }
        }

        &__content {
            position: absolute;
            @include gradient-dull-royal();
            height: 50px;
            width: 50px;
            border-radius: 50%;
            border: 8px solid $color-whisper;
            top: -19px;
            z-index: -1;
        }

        &__menu {
            width: 25px;
        }

        &__text {
            @include global-typography($color-amethyst-smoke,10px,10px,bold);
            width: 45px;

            &-mod{
            @include global-typography($color-royal-blue,10px,10px,bold);
            width: 45px;

            }
        }

        &__img {
            width: 17px;

            &-mod{
            position:relative;
            height: 21px;
            width: 12px;
            top: -3px;
            }

            &__active {
                top: -4px;
                position: relative;
                height: 17px;
            }
        }
    }

    &__button{
        width: calc(100%/5);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        line-height: 40px;

        &__container{
            justify-content: space-evenly;

            &--mod{
                justify-content: space-between;
            }
        }
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small) {
    .tab-bar {
        bottom: 0;
        width: 100%;
        left: 0;
        z-index: 1;

        &__container {
            width: 100%;
            border-radius: 0;
            min-width: 0;
            z-index: 0;
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
        }
    }
}
@import "../../../../../shared/styles/styles.scss";

.personal-information {
    height: 50vh;

    &__content{
        height: 100%;
        overflow-y: scroll;

        &::-webkit-scrollbar{
            display: none;
        }
    }

    &__title{
        @include global-typography($color-meteorite,20px,18px, bold);
    }
    
    &__description{
        border-bottom: 1px solid $color-blue-haze;
        margin-bottom: 7%;
        @include global-typography($color-amethyst-smoke,20px,18px, bold);
    
        &__right{
            width: 13%;
            margin-right: 5px;
        }
    
        &__left{
            width: 82%;
            padding-left: 3%;
            margin-left: 5px;
        }
    }
    
    &__box{
        justify-content: space-between;
    }
};

//media query
@media screen and (max-width: $media-query-medium) {  
    .personal-information {
        height: 31vh;
    }
} 
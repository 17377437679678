@import "../../../../../../../shared/styles/styles.scss";

.modal-unsubscribe-myAccount{

    &__body{
        overflow: hidden;
    }

    &__content {
        height: 55%;
        width: 90%;
        display: flex;
        justify-content: center;
        position: relative;
        border-radius: 10px;
        background: $color-white;

        &__overlay {
            z-index: 2;
            @include global-modal-overlay;
            opacity: 1;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: fixed;
            align-items: center;
        }
    }

    &__container {
        padding: 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__content-close {
            height: 6%;
            display: flex;
            justify-content: flex-end;
        }

        &__content-information {
            height: 94%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between
        }

        &__title {
            width: 90%;
            text-align: center;
            @include global-typography($color-meteorite,24px, 24px, bold);

            &--mod {
                width: 50%;
                @extend .modal-unsubscribe-myAccount__container__title
            }
        }

        &__description {
            width: 72%;
            text-align: center;
            @include global-typography($color-amethyst-smoke, 18px, 18px, normal);
        }

        &__content-btn {
            width: 100%;
            height: 64px;
            display: flex;
            flex-direction: column;
            justify-content: space-between
        }

        &__btn {
            width: 100%;
            height: 40px;
            background-color:$color-royal-blue;
            border-radius: 10px;
            @include global-typography($color-white,20px,16px,bold)
        }

        &__btn-text {
            @include global-typography($color-meteorite, 18px, 18px, normal);
        }
    }
}
@import "../../../../../shared/styles/styles.scss";

.modal-information {
    width: 90%;
    height: 80%;
    background: $white;
    border-radius: 8px;
    padding: 15px;

    &__overlay {
        background-color: $color-overlay-amethyst-smoke;
        backdrop-filter: blur(3px);
        z-index: 2;
        opacity: 1;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &__body{
        overflow: hidden;
    }

    &__logo {
        padding-left: 15px;
        width: 115px;

        &__text {
            padding: 2px 0px 0px 3px;
            @include global-typography($color-rum, 18px, 15px, bold)
        }
    }

    &__close {
        width: 12px;
        margin-right: 10px;
    }

    &__icon-container {
        width: 100px;
        margin-left: 25px;

        &__img {
            width: 75px;

            &--inactive {
                @extend .modal-information__icon-container__img;
                filter: grayscale(100%);
            }
        }

        &__icon {
            margin-left: 45%;
            margin-top: -30%;
            width: 30px;
            height: 30px;
            z-index: 1;
        }

        &__count {
            width: 25px;
            height: 25px;
            align-self: flex-end;
            border-radius: 100%;
            background-color: $color-royal-blue;
            position: relative;
            right: 25px;
            @include global-typography($white, 20px, 15px, 600);
        }

        &__text {
            @include global-typography($color-meteorite, 28px, 22px, bold);
        }
    }

    &__tap {
        @include global-typography($color-blue-haze, 20px, 18px, bold);
        transition: color .3s ease;

        &--active {
            @include global-typography($color-meteorite, 20px, 18px, bold);
        }

        &__state {
            background-color: $color-blue-haze;
            width: 100%;
            transition: background-color .3s ease;

            &--active {
                @extend .modal-information__tap__state;
                background-color: $color-royal-blue;
            }
        }
    }

    &__button {
        @include global-button(80%, 100%);
        @include global-typography($white, 20px, 18px, bold);
    }
}
@import '../../../../../shared/styles/styles.scss';

.modal-term-conditions {
    width: 100%;
    height: 100%;
    background: $color-athens-gray;
    padding: 20px 20px 0 20px;

    display: grid;
    row-gap: 20px;
    grid-template-rows: 80px auto;

    &__overlay {
        z-index: 2;
        opacity: 1;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &__body {
        overflow: hidden;
    }

    &__header {
        background-color: $color-white;
        border-radius: 10px;
        padding: 15px 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        button {
            display: inline-flex;
            align-items: center;
            @include global-typography($color-meteorite, 14px, 16px, bold)
        }
        
        &__icon {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background-color: $color-athens-gray;
            display: grid;
            place-items: center;
            margin-right: 5px;
        }

        &__logo{
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-end;
        }
    }
    
    &__content{
        background-color: $color-white;
        border-radius: 10px;
        padding: 30px 20px;
        margin-bottom: 25px;
        overflow-y: scroll;

        display: grid;
        grid-template-rows: minmax(26px, auto) auto;
        row-gap: 27px;
        @include global-typography($color-amethyst-smoke, 16px, 14px, 500);
        
        &__title{
            @include global-typography($color-meteorite, 20px, 22px, bold);
        }

        &::-webkit-scrollbar{
            display: none;
        }
    }
}
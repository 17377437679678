@import "../../../../../../shared/styles/styles.scss";

.household-data-map {
    width: 100%;
    overflow-y: scroll;
    height: 100%;
    box-shadow: 0px 0px 12px $color-box-shadow;
    border-radius: 10px;
    opacity: 1;
    background-color: $color-white;
    padding: 20px;
    padding-bottom: 140px;

    &__loading{
        width: 100%; 
        height: 100%;
        background-color: $color-whisper;

        &--mod{
            width: 100%;
            height: 200px;
            background-color: $color-white;
        }
        
        &-spinner{
            animation: spinner 1.5s linear infinite;
        }

        &__text{
            @include global-typography($color-meteorite, 18px, 18px, bold);
            padding-top:40px;
        }
    }

    &__error{
        display: grid;
        grid-template-rows: repeat(3, auto);
        place-items: center;
        padding: 25px 35px;
        background: $color-whisper;
        width: 100%; 
        height: 100%;

        &>img{
            height: 80px;
            width: 80px;
        }

        &__button {
            @include global-button(43px, 100%);
            @include global-typography($color-white, 20px, 14px, bold);
        }

        &__container{
            width: 100%;
        }
        &__text{
            @include global-typography($color-meteorite, 16px, 14px, bold);
        }
    }
    
    &::-webkit-scrollbar {
        display: none;
    }
    
    &__content-map {
        width: 100%;
        height: 280px;
        position: relative;
        display: flex;
        justify-content: center
    }
    
    &__button-map {
        @include global-button(30px, 220px);
        @include global-typography($color-white, 20px, 14px, bold);
        position: absolute;
        bottom: 20px;

        &--mod {
            @include global-button(30px, 220px);
            @include global-typography($color-white, 20px, 14px, bold);
            position: relative;
            width: 46%;
            bottom: 20px;
        }

        &--color{
            background-color: $color-amethyst-smoke;
        }

        &__container {
            position: absolute;
            bottom: 0;
            width: 100%;
            justify-content: space-evenly;
        }
    }

    &__form{
        width: 100%;
    }

    &__field{
        width: 100%;
        margin-top: 20px;

        &__label{
            @include global-typography($color-meteorite, 18px, 18px, bold);
        }
    }

    &__input.MuiFormControl-root {

        width: 100%;
        margin-top: 15px;

        &>label {

            z-index: 0;

            &.MuiFormLabel-root {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -15px;
                z-index: 2;

                &+div>div{
                    top: 15px;
                    right: 2px;
                }
            }

            &.MuiInputLabel-shrink {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -5px;
                transform: translate(14px, -14px);
                width: 100%;

                &+div>div{
                    top: auto;
                }
            
                
            }
        }


        &>div {

            &:hover {
                &>fieldset {
                    border-color: $color-amethyst-smoke;
                }
            }

            &.Mui-focused {
                &>fieldset {
                    border-bottom: 2px solid $color-blue-haze;
                }
            }

            &.Mui-error {
                &>input {
                    &+fieldset {
                        border-bottom: 1px solid $color-error-rose;
                    }
                }
            }

            &>input {
                padding: 0 0 0 4px;
                box-shadow: none;
                border: 0;
                background: transparent;
                align-self: flex-end;
                cursor: text;
                @include global-typography($color-amethyst-smoke, 20px, 18px, bold);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-text-fill-color: $color-amethyst-smoke;


                &+fieldset {
                    border: 0;
                    border-bottom: 1px solid $color-blue-haze;
                    border-radius: 0;
                }
            }

            &.Mui-disabled {
                background-color: $color-blue-haze;
            }

        }

    }

    &__option {
        @include global-typography($color-meteorite, 23px, 18px, bold);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__input-autocomplete.MuiFormControl-root {

        @extend .household-data-map__input;
        padding-left: 0;

        &>label {

            &.MuiFormLabel-root {
                top: 0;
            }
        }

        &>div{
            padding: 0;


            &>fieldset {
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid $color-blue-haze;
            }
        }

        &>.Mui-error {
            padding: 0;


            &>fieldset {
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid $color-error-rose;
            }
        }

    }

    &__photo-residence{
        width: 100%;
        margin-top: 20px;
        border:dashed 3px $color-whisper;
        border-radius: 10px;
        height: 90px;

        &__error{
            @extend .household-data-map__photo-residence;
            border-color: $color-error-rose;
        }

        &__icon{
            @include gradient-dull-royal;
            border-radius: 100%;
            height: 50px;
            width: 50px;
            box-shadow: 0px 0px 12px $color-box-shadow;
            cursor: pointer;

            &__check{
                position: relative;
                right: 20px;
                top: 15px;
                height: 20px;
            }
        }

        &__text{
            @include global-typography($color-amethyst-smoke,16px,14px,bold);
            margin-left: 5%;
        }

        &__name{
            @include global-typography($color-meteorite,20px,18px,bold);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__button{
            @include global-typography($color-royal-blue,20px,18px,bold);
            margin-right: 5px;
        }

        &__cursor{
            cursor: pointer;
        }
    }

    &__hidden{
        display: none;
    }
}
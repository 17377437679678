@import "../../../../../shared/styles/styles.scss";

.contact-card{

    &__box{
        height: 296px;
        padding: 12px;
        border-radius: 10px;
        margin: 0px 10px 10px 10px;
        background-color: $color-white;
        box-shadow: 0px 0px 12px $color-box-shadow;

        &--isDesktop{
            height: 200px;
            @extend .contact-card__box;
        }

        &__close{
            width: 100%;
            display: flex;
            justify-content: flex-end;

            &--mod {
                z-index: 1;
                position: relative;
                @extend .contact-card__box__close;
            }
        }
    }

    &__content-info{
        height: 85%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        &__content-img{
            position: relative;
        }

        &__img{
            height: 100px;
            width: 100px;
            border-radius: 100%;
        }

        &__img-warning{
            height: 40px;
            width: 30px;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
        }

        &__box-text{
            height: 40px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
        }

        &__name-user{
            @include global-typography($color-meteorite,20px,20px, bold);
        }

        &__email{
            @include global-typography($color-amethyst-smoke, 12px, 14px, normal);
        }

        &__content-buttons{
            width: 60%;

            &__btn {
                z-index: 1;
                position: relative;
            }

            &__inactive-buttons{
                filter: grayscale(100%);
            }

            &__text{
                width: 65px;
                margin-top: 6px;
                text-align: center;
                @include global-typography($color-amethyst-smoke, 12px, 14px, normal);

                &--mod{
                    @include global-typography($color-meteorite, 12px, 14px, normal);
                    @extend .contact-card__content-info__content-buttons__text;
                }
            }
        }

        &__inactive-buttons{
            pointer-events: none; 
            cursor: default; 
            filter: grayscale(100%);
        }
    }
}
@import "../../../../../../shared/styles/styles.scss";

.modal-successful-change{
    width: 90%;
    height: 450px;
    background: $white;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    @include global-typography($color-meteorite, 20px, 20px, bold);

    &__loading{
        width: 90%;
        height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__icon-loading{
            margin-bottom: 10%;
            animation: spinner 1.5s linear infinite;
        }
    }

    &__title{
        @include global-typography($color-meteorite, 21px, 24px, bold);
    }

    &__icon{
        width: 110px;
        height: 110px;
    }

    &__body{
        overflow: hidden;
    }

    &__overlay {
        background-color: $color-overlay-amethyst-smoke;
        backdrop-filter: blur(3px);
        z-index: 2;
        opacity: 1;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &__button {
        @include global-button(70%, 100%);
        @include global-typography($white, 20px, 18px, bold);
    }
}
@import "../../../../../shared/styles/styles.scss";


.view-profile{
    height: 100vh;
    padding-top: 12px;
    background-color: $color-whisper;

    &__isDisabled{
        z-index: 1;
        width: 100%;
        height: 100vh;
        padding-top: 12px;
        padding-bottom: 10%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-color: $color-box-shadow;
        top: 0;

        &__exitPlatform{
            width: 90%;
            height: 40px;
            position: fixed;
            border-radius: 10px;
            background-color:$color-royal-blue;
            @include global-typography($color-white,20px,16px,bold)
        }

        &__back{
            @include global-typography($color-amethyst-smoke, 18px, 18px, normal);
        }
    }

    &__container{
        padding: 0px 10px;
    }

    &__box{
        &__box-buttons{
            height: 90px;
            justify-content: center;
            align-items: center;

            &__button-active{
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                background-color: $color-white;
            }
        }

        &__img{
            margin-bottom: 8px;

            &__inactive{
                filter: grayscale(100%);
                @extend .view-profile__box__img
            }
        }

        &__text{
            width: 65px;
            text-align: center;
            @include global-typography($color-amethyst-smoke, 12px, 14px, bold);
        }

        &__text-inactive{
            width: 65px;
            text-align: center;
            @include global-typography($color-blue-haze, 12px, 14px, bold);
        }
    }

    &__footer{
        background-color: $color-white;
        padding: 10px 25px 10px 25px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin: 0px 10px 0px 10px;
        box-shadow: 0px 0px 12px $color-box-shadow;
        margin-bottom: 15%;

        &__box-right{
            border-top-right-radius: 10px;
        }

        &__box-left{
            border-top-left-radius: 10px;
        }

        &__box-center{
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }

        &__button{
            width: 100%;
            background-color:$color-royal-blue;
            height: 40px;
            border-radius: 10px;
            @include global-typography($color-white,20px,18px,bold);
            &__text{
                @include global-typography($color-amethyst-smoke,18px,18px,normal);
                width: 100%;
            }
        }
    }
}

@import "../../../../../../../shared//styles/styles.scss";

.levels-information {

    &__icon-container {
        width: 75px;
        height: 100px;

        &__icon {
            width: 100%;
            height: 75px;
            border-radius: 100%;
        }

        &__text {
            @include global-typography($color-meteorite, 28px, 22px, bold)
        }
    }

    &__summary {
        height: 85%;
        border: 1px solid $color-athens-gray;
        border-radius: 14px;
        padding: 3%;
        margin-top: 10px;
        overflow-y: scroll;
        position: relative;
        display: relative;

        &__title {
            @include global-typography($color-meteorite, 18px, 14px, bold);
            margin-bottom: 2px;
        }

        &__description {
            @include global-typography($color-amethyst-smoke, 9px, 9px);
            margin-bottom: 0px;
        }

        &__box-one {
            height: 75px;

            &__img-container {
                width: 45%;
                height: 100%;
                position: relative;

                &__img {
                    min-width: 150px;
                    min-height: 188px;
                    z-index: 2;
                    position: absolute;
                    top: 9px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }

        &__box-two {
            height: 160px;
            padding: 5px;

            &__first-colum {
                height: 100%;

                &__first-row {
                    height: 63px;
                    width: 100%;
                    border-radius: 8px;
                    padding-right: 3px;
                    border: 1px dashed $color-box-shadow ;
                    background-color: $color-whisper;
                }

                &__second-row {
                    height: 75px;
                    width: 100%;
                    border-radius: 8px;
                    padding-right: 3px;
                    border: 1px dashed $color-box-shadow;
                    background-color: $color-whisper;
                }

                &__content {
                    width: 46%;
                }
            }

            &__second-colum {
                padding-top: 10px;
                height: 100%;
                justify-content: space-between;
                align-items: center;
                position: relative;

                &__text {
                    transform: rotate(-90deg);
                    width: 130px;
                    text-align: center;
                    align-items: center;
                    @include global-typography($color-meteorite, 13px, 13px, bold);
                }
            }
        }

        &__box-three {
            height: 50px;
            text-align: center;
            justify-content: end;

            &__text {
                width: 75%;
                @include global-typography($color-amethyst-smoke, 9px, 9px);
            }

            &__link {
                &__icon {
                    height: 15px;
                }

                &__text {
                    margin-left: 3px;
                    @include global-typography($color-meteorite, 18px, 12px, bold);
                }
            }
        }
    }

    &__description {
        height: 85%;
        border: 1px solid $color-athens-gray;
        border-radius: 14px;
        padding: 3%;
        margin-top: 10px;
        overflow-y: scroll;
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }

        &__text-description {
            @include global-typography($color-amethyst-smoke, 14px, 16px, 500)
        }
    }
}
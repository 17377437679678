@import './../../../styles/styles.scss';

.contact-list-skeleton{
    height: 100vh;

    &__container{
        padding: 5px 90px 5px 25px;
    }

    &__img{
        
        &__content{
            width: 35px;
            height: 35px;
            background-color: $color-whisper;
            border-radius: 25px;
            -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
            animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;

        }
    }

    &__letter{
        background-color: $color-whisper;
        height: 25px;
        width: 100%;
        -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
        animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
    }

    &__name{
        width: 80%;
        height: 25px;
        display: block;
        background-color: $color-whisper;
        margin: 10px 0 10px 10px;
        padding: 10px 0 10px 0;
        transform-origin: 0 55%;
        border-radius: 9px/6.7px;
        -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
        animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
    }

}
@import '../../../shared/styles/styles.scss';

.login {

    &__content{
        height: 100vh;
        
    }
    
    &__footer{
        background-color: $color-white;
        width: 100%;
        padding: 20px;
        &__line{
            height: 25px;
            width: 1px;
            background-color: $color-blue-haze;
        }

        &__content{
            justify-content: space-evenly;
        }

        &__button{
            color: $color-meteorite;
            font-size: 20px;
        }
    }

    &__user{
        padding: 20px 0 0 0;
        justify-content: space-between;
        

        &__title{
            @include global-typography($color-meteorite, 17px, 16px, bold );
            line-height: 1px;
            &--mod{
                @include global-typography($color-meteorite,17px,20px,bold)
            }
        }

        &__text{
            padding-top: 5px;
            width: 330px;
            @include global-typography($color-meteorite, 14px, 16px, normal);
            margin-top: 1.4rem;
        }

        &__phone{
            width: 100%;
            justify-content: center;

            &__content{
                width: 100%;
                margin-bottom: 10px;
            }
        }

        &__input-phone {

            background-color: $color-white;

            &__content {
                justify-content: space-between;     

                &>div.MuiFormControl-root {
                    width: 80%;
                }
            }
    
            &.react-tel-input {
                margin-bottom: 3px;
                width: 10%;
                display: flex;
                align-items: flex-end;
    
                &>div.flag-dropdown {
                    background: transparent;
                    border: 0;
                    width: 52px;
    
                    &>div.selected-flag {
                        background: transparent;
    
                        &>div.flag {
                            background: transparent;
                            margin-top: 8px;
    
                            &>div.arrow {
                                top: 20%;
                                left: 32px;
                            }
                        }
                    }
                }
    
                &>input.form-control {
                    box-shadow: none;
                    padding-left: 4px;
                    width: 52px;
                    border: 0;
                    border-bottom: 1px solid $color-meteorite;
                    border-radius: 0;
                    @include global-typography($color-meteorite,23px,18px,bold);
                    padding-top: 25px;
                    background-color: $color-white;
    
                    &::placeholder {
                        @include global-typography($color-meteorite,14px,16px,normal);
                    }
                }
            }
        }

        &__input{
            border: 0;
            border-bottom: 1px solid $color-meteorite;
            border-radius: 0;
            color: $color-meteorite;
            font-style: normal;
            font-variant: normal;
            font-weight: bold;
            width: 100%;

            &::placeholder { 
                @include global-typography($color-meteorite,14px,16px,normal); 
            }

            &:focus-visible {
                outline: none;
                color: $color-meteorite;
            }

            &--mod{
                height: 21px;
                margin-bottom: 3px;
                width: auto;
                color: $color-meteorite; 
            }

            &__content{
                margin-bottom: 12px;
                padding-top: 10%;
                width: 80%;
            }
            &__type{
                color: $color-meteorite;

            }

            &__container{
                width: 65%;
                margin-left: 25px;
            }

            &__error{
                color: red;
                font-size: 10px;
            }
        }
    }

    &__button {

        &[type=submit]{
            @include global-button(40px,94%);
            @include global-typography($color-white,20px,18px,bold);
            line-height: 40px;
            margin: 10px 0 0 0
        }
        
        &__change-data{
            margin-top: 30px;
            margin-bottom: 10px;
            text-decoration: underline;
            text-underline-offset: 3px;
            @include global-typography($color-royal-blue, 14px, 16px, bold);
        }

        &__container{
            margin-top: 12px;
            margin-bottom: 10px;
            line-height: 6px;
            height: 50px;
            margin: 10px;
        }

        &__text{
            color:$color-amethyst-smoke;
            margin: 0 0 30px 0;
        }
    }
}

//media query
@media (max-width: $media-query-medium) {
    .login {
        &__container{
            height: 90vh;
        }
        &__user{
           
        }
    }
}

//landscape
@media (orientation: landscape) {
    .login {
        
        &__container{
            height: auto;
        }

        &__footer{
            height: auto;
        }

        &__user{
            height: 65vh;
            &__title{
                &--mod{
                    width: auto;
                }
            }
            &__input{
                width: 100%;
                &__content{
                    width: 80%;
                }
            }
        }
        &__button{
            height: auto;
            &__container{
                height: auto;
            }
        }
    }
}
@import '../../../shared/styles/styles.scss';

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 100px;
    height: 100px;
    animation: spinner 1.5s linear infinite;
}

.react-modal {
    position: absolute;
    inset: 0px;
    border: 0 solid rgb(204, 204, 204, 0);
    background: rgb(255, 255, 255, 0);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;

    &__container {
        position: absolute;
        bottom: 22%;
        left: 47%;

        &__text {
            position: fixed;
            bottom: 14%;
            left: 43%;
            color: $color-meteorite;
            letter-spacing: 0px;
            font-size: 14px;
        }
    }
}


//media queries
//Small
@media screen and (max-width: $media-query-small) {
    .react-modal {
        &__container {
            position: absolute;
            bottom: 26%;
            left: 37%;

            &__text {
                position: fixed;
                bottom: 9%;
                left: 35%;
                letter-spacing: 0px;
                font-size: 12px;
            }
        }
    }
}
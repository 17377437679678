@import "../../../../../shared/styles/styles.scss";


.view-contact{

    &__container{
        background-color: $color-whisper;
        padding-top: 10px;
        height: 100%;
    }

    &__target{
        background-color: $color-whisper;
    }

    &__box{ 
        height: 296px;
        padding: 12px;
        border-radius: 10px;
        margin: 0px 20px 10px 20px;
        background-color: $color-white;
        box-shadow: 0px 0px 12px $color-box-shadow;

        &__close{
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

        &__content-info{
            height: 85%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            &__img{
                height: 101px;
                width: 101px;
                border-radius: 100%;
            }
    
            &__content-user{
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;
            }
    
            &__box-text{
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                max-width: 95%;
            }
    
            &__name-user{
                @include global-typography($color-meteorite,30px,20px, bold);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
    
    
            &__content-buttons{
                width: 60%;
    
                &__text{
                    width: 65px;
                    margin-top: 6px;
                    text-align: center;
                    @include global-typography($color-amethyst-smoke, 12px, 14px, normal);
                }

                &__inactive{
                    pointer-events: none; 
                    cursor: default; 
                    filter: grayscale(100%);
                }
            }
    
        }
    }

    &__box-contacts{ 
        display: flex;
        height: 74px;
        padding: 12px;
        border-radius: 10px;
        margin: 0px 10px 18px 10px;
        background-color: $color-white;
        box-shadow: 0px 0px 12px $color-box-shadow;
        flex-direction: row;
        justify-content: space-between;
        
        &__content-info{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &__title{
                @include global-typography($color-meteorite,20px,18px, bold);
            }
    
            &__description{
                @include global-typography($color-amethyst-smoke, 20px, 18px, bold);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

    }

    &__box-buttons{
        width: 100%;
        display: flex;;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
        padding: 10% 0 30px 0;

        &__button{
            width: 80%;
            height: 40px;
            background-color:$color-royal-blue;
            border-radius: 10px;
            @include global-typography($color-white,20px,18px,bold);

            &__text{
                margin-top: 10px;
                @include global-typography($color-amethyst-smoke,18px,18px,normal);
            }
        }
    }
}
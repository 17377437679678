@import "./../../../styles/styles.scss";

.box-member-skeleton {

    &__show{
        display: none
    }

    &__content {
        padding: 0 15px;
        width: 100%;
    }

    &__container {
        background-color: $color-whisper;
        padding: 5px 0;
        min-width: 0;
        border-radius: 8px;
        width: 100%;
        max-width: 420px;
        flex-direction: column;
        align-items: center;
        display: flex;
        transition: 0.2s ease-out;
        justify-content: flex-start;

        &--mod {
            padding: 13px;
        }
    }

    &__member {
        background-color: blue;
        width: 100%;
        height: 97px;
        margin-top: 1%;
        border-radius: 10px;

        &__header {
            width: 95%;
            padding: 2%;
            background: $color-white;
            margin-top: 5px;
            justify-content: space-between;
            border-radius: 8px 8px 0px 0px;
        }

        &__button {
            width: 90px;

            &__circle{
                display: block;
                width: 50px;
                height: 50px;    
                background-color: $color-whisper;
                border-radius: 50%;
                -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
            }
            &__text{
                display: block;
                background-color: $color-whisper;
                width: 50px;
                height: 20px;
                margin-top: 4px;
                margin-bottom: 0;
                transform-origin: 0 55%;
                border-radius: 9px/6.7px;
                -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
            }

            &--active {
                background: #EEEEEE;
                border-radius: 10px;
            }
        }
        &--mod{
            background-color: $color-whisper;
        }

        &__box {
            background-color: rgb(255, 255, 255);
            border-radius: 0 0 8px 8px;
            width: 95%;
            max-width: 400px;
            flex-direction: column;
            align-items: center;
            display: flex;
            transition: 0.2s ease-out;
            justify-content: flex-start;
            align-content: flex-start;
        }

        &__text{
            display: block;
            border-radius: 10px;
            top: 10px;
            height: 1.2em;
            width: 40px;
            background-color: $color-whisper;
            -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
            animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
        }

    }

    &__line {
        background-color: $color-whisper;
        height: 17px;
        width: 3px;

        &__circle {
            height: 8px;
            width: 8px;
            border: 2px solid #B9B9B9;
            border-radius: 100%;
        }
    }

    &__number-member {
        color: white;
        display: flex;
        background-color: $color-whisper;
        padding: 4px;
        margin-left: 60%;
        margin-top: -25%;
        margin-bottom: 0;
        width: 20px;
        height: 20px;
        font-size: 0.8rem;
        border-radius: 40px;
        align-items: center;
        justify-content: center;
    }
}


//media queries
//large

@media screen and (min-width: $media-query-medium) {

    .box-member-skeleton {

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }

        &__text {
            display: none;
        }

        &__line {
            background-color: $color-whisper;
            height: 40px;
            width: 3px;

            &__circle {
                height: 10px;
                width: 10px;
                border: 2px solid #B9B9B9;
                border-radius: 100%;
            }
        }
    }
}

//media queries
//small
@media screen and (max-width: $media-query-small) {
    .box-member-skeleton {
        &__delete-modal {
            height: 50%;
            width: 100%;
        }

        &__number-member {
            width: 15px;
            height: 15px;
            font-size: 0.6rem;

            &--mod {
                font-size: 0.4rem;
            }
        }
    }
}

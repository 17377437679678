@import "../../../shared/styles/styles.scss";

.group-and-role-selection{

    &__content{
        width: 315px;
        height: 75px;
        border-radius: 10px;
        padding: 0 5px 0 15px;
        margin-bottom: 12px;
        background: $color-athens-gray;
    }

    &__container{
        width: 100%;
        justify-content: space-between;
    }

    &__icon{
        width: 45px;
        color: $dark-gray;
    }

    &__text{
        @include global-typography($color-meteorite,14px,16px,normal);

        &--mod{
            font-weight: bold;
        }
    }

    &__icon-next{
        margin: 0 10px 0 0 ;
        font-size: 25px;
        color: $color-meteorite;
    }

    &__button{
        height: 40vh;
        overflow-y: scroll;
        padding: 15px;
    }

    &__exit{
        @include global-typography($color-amethyst-smoke,18px,18px, normal);
    }


    &__footer{
        background-color: $white;
        width: 100%;
        padding: 20px;

        &__line{
            height: 25px;
            width: 1px;
            background-color: $color-blue-haze;
        }

        &__content{
            justify-content: space-evenly;
        }
    }

    &__user{
        padding: 20px 0 0 0;
        height: 50vh;
        justify-content: space-evenly;

        &__text{
            @include global-typography($color-meteorite,18px,16px, normal); 
            line-height: 12px;
            padding-top: 15px;
        }
    }


    &__button {

        &__container{
            margin-top: 5px;
            margin-bottom: 7px;
        }
    }

}

//media query

//landscape

    @media (orientation: landscape) {
        .group-and-role-selection{
            &__button{
                height: 57vh;
            }
        }
    }
@import "../../../../../../../shared/styles/styles.scss";

.preview-document{
    &__overlay {
        background-color: $color-overlay-amethyst-smoke;
        backdrop-filter: blur(3px);
        z-index: 2;
        opacity: 1;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &__content{
        width: 95%;
        height: 95%;
        background: $white;
        padding: 15px;
    }

    &__container{
        height: 100%;
    }

    &__body{
        overflow: hidden;
    }

    &__pdf{
        overflow:scroll;
        height:90%;
        width:100%;
        border: 1px solid $color-blue-haze;
        margin-top: 10px;
        &::-webkit-scrollbar{
            overflow: hidden;
        }

        &__adjust{
            height: 103% !important;
            width: 105% !important;
        }

        &__content{
            width: 50%;
        }

        &__container{
            margin-top: 7px;
        }

        &__hide {
            visibility: hidden;
        }
    }

    &__button {

        &__text{
            @include global-typography($color-meteorite, 20px,12px, bold);

            &:disabled{
                color: $color-amethyst-smoke;
            }
        }

        &__circle{
            background: $color-royal-blue;
            height: 20px;
            width: 20px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &__icon{
                @include global-typography($color-white, 12px,14px, bold);
                height: 15px;
                width: 15px;
            }

            &:disabled{
                background: $color-amethyst-smoke;
            }
        }

        &__back{
            rotate: 90deg;
            width: 15px;

            &--mod{
                @extend .preview-document__button__back;
                rotate: 270deg;
            }
        }

        &__center{
            color: $color-blue-haze;
        }
    }

    &__zoom{
        width: 25%;

        &__text{
            @include global-typography($color-amethyst-smoke,12px,14px,bold);
        }
    }
    &__loading{
        background: $color-whisper;
        height: 100%;
        width: 100%;
        &-spinner{
            animation: spinner 1.5s linear infinite;
        }
        &__text{
            @include global-typography($color-meteorite, 18px, 18px, bold);
        }
    }
}
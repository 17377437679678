@import "../../../../../shared/styles/styles.scss";

.moda-solve-map {
    width: 90%;
    max-height: 80%;
    background: $white;
    border-radius: 8px;
    padding: 20px;

    display: grid;
    row-gap: 20px;
    grid-template-rows: repeat(3, 1fr) auto;

    &__overlay {
        background-color: $color-overlay-amethyst-smoke;
        backdrop-filter: blur(3px);
        z-index: 2;
        opacity: 1;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &__body {
        overflow: hidden;
    }

    &__close-container {
        display: inline-flex;
        justify-content: flex-end;
    }

    &__button-close {
        padding: 0 4px;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        transition: all 0.2s;

        &__text {
            @include global-typography($color-meteorite, 14px, 16px, bold);
            margin-right: 6px;
        }

        &__icon {
            padding: 8px;
            display: grid;
            place-items: center;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background-color: $color-athens-gray;
        }

        &:active {
            background-color: $color-athens-gray;
            border-radius: 8px;
        }
    }

    &__description {
        padding: 0 20px;
        align-content: center;
        text-align: center;
        @include global-typography($color-meteorite, 16px, 14px, bold);
    }

    &__steps {
        display: inline-flex;
        justify-content: center;
        border-bottom: 2px solid $color-athens-gray;

        &__item {
            height: 100%;
            margin: 0 15px;
            @include global-typography($color-meteorite, 14px, 16px, bold);
            transition: all 0.2s;

            &:active {
                background-color: $color-athens-gray;
                border-radius: 8px 8px 0px 0px;
            }

            &--active {
                color: $color-royal-blue;
                border-bottom: 3px solid $color-royal-blue;
            }
        }
    }

    &__content {
        border-radius: 8px;
        background-color: $color-athens-gray;
        padding: 15px 20px;

        display: grid;
        grid-template-rows: minmax(35px, auto) auto;
        row-gap: 20px;

        &__container {
            padding-bottom: 13px;
        }

        &__steps {
            @include global-typography($color-meteorite, 14px, 12px);
            margin-bottom: 0;

            li {
                margin: 15px 0;
                img{
                    margin: 0 2px;
                }
            }

            li::marker {
                font-weight: bold;
            }
        }

        &__text {
            @include global-typography($color-meteorite, 16px, 14px, bold);
        }

        &__text-strong {
            @include global-typography($color-meteorite, 16px, 14px, bold);
        }
    }
}

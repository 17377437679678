@import '../../../styles/styles.scss';

.view-contact-skeleton{

    &__container{
        background-color: $color-whisper;
        padding-top: 10px;
        height: 100%;
    }

    &__target{
        background-color: $color-whisper;
    }

    &__box{ 
        height: 296px;
        padding: 12px;
        border-radius: 10px;
        margin: 0px 20px 10px 20px;
        background-color: $color-white;
        box-shadow: 0px 0px 12px $color-box-shadow;

        &__content-info{
            height: 85%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between; 
    
            &__content-user{
                display: block;
                align-items: center;
                flex-direction: column;
                width: 100px;
                height: 100px;
                background-color: $color-whisper;
                border-radius: 50px;
                -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
            }
    
            &__box-text{
                height: 35px;
                flex-direction: column;
                justify-content: space-evenly;
                max-width: 95%;
                display: block;
                background-color: $color-whisper;
                width: 40%;
                transform-origin: 0 55%;
                border-radius: 9px/6.7px;
                -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
            }
    
            &__content-buttons{
                width: 60%;
                justify-content: space-around;

                &__text{
                    margin-top: 30px;

                    width: 50px;
                    height: 50px;
                    background-color: $color-whisper;
                    border-radius: 25px;
                    -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                    animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                }

            }
    
        }
    }

    &__box-contacts{ 
        display: flex;
        height: 74px;
        padding: 12px;
        border-radius: 10px;
        margin: 0px 10px 18px 10px;
        background-color: $color-white;
        box-shadow: 0px 0px 12px $color-box-shadow;
        flex-direction: row;
        justify-content: space-between;
        
        &__content-info{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &__title{
                width: 60%;
                    height: 30px;
                    display: block;
                    background-color: $color-whisper;
                    margin: 10px 0 10px;
                    padding: 10px 0 10px 0;
                    transform-origin: 0 55%;
                    border-radius: 9px/6.7px;
                    -webkit-animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
                    animation: animation-c7515d 1.5s ease-in-out 0.5s infinite;
            }
        }
    }
}
@import "../../../../../shared/styles/styles.scss";

.contact-list{

    &__container{
        width: 100%;
        padding-bottom: 10px;
        background-color: #F1F1F8;
    }

    &__header{
        height: 48px;
        padding: 10px 15px;
        box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.1);
        background-color: #FFFFFF;

        &--img{
            padding: 0 0 0 10px;
            height: 23px;
        }

        &__input-filter {
            margin: 0;
            width: 100%;
            height: 38px;
            display: block;
            outline: none;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.5;
            appearance: none;
            border-width: 0px;
            border-radius: 10px;
            font-family: inherit;
            box-sizing: border-box;
            color: $color-meteorite;
            padding: 0px 40px 0px 15px;
            background-color: $color-white;
            box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
            transition: box-shadow 0.5s, border-color 0.25s ease-in-out;

            &::placeholder{
                color:  $color-meteorite;
            }
            
            &__icon {
                right: 23px;
                font-size: 20px;
                position: absolute;
                color: $color-blue-haze;
            }

            &__container{
                width: 100%;
                height: 55px;
                display: flex;
                align-items: center;
                position: relative;
                margin: 0 auto;
                padding: 10px 12px;
                background-color:  $color-whisper;
            }
        }

        &__title{
            @include global-typography($color-meteorite,20px,20px,bold);
            margin: 0;
        }

        &__back{

            &__text{
                @include global-typography($color-meteorite,14px,20px,bold);
                margin-left: 10px;
            }
        }
    }

    &__img{
        color: $light-gray;
        height: 30px;
        width: 30px;
        margin-right: 7px;
        border-radius: 100%;

        &__content{
            width: 80%;
            text-align: left;
            padding: 10px 0 5px;
            display: flex;

            &--mod{
                border-bottom: 1px solid $light-gray;
            }
        }
    }

    &__section {
        overflow: scroll;
        max-height: 65vh;
        width: 100%;
        padding-bottom: 40px;

        &__letter{
            background-color: $color-whisper;
            position: sticky;
            top: -1px;
    
            &__box{
                margin-left: 10%;
                font-size: 16px;
                font-weight: 400;
                color: $color-meteorite;
                font-weight: bold;
            }
        }
    }


    &__name{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
        margin: auto 0;
        line-height: normal;
        font-size: 14px;
        font-weight: 400;
        color: $color-meteorite;
        font-weight: bold;
    }

    &__no-contacts-container{
        height: 80vh;

        &__title{
            @include global-typography($color-meteorite,20px,20px,bold);
        }

        &__description{
            margin-top: 5px;
            @include global-typography($color-amethyst-smoke,14px,14px, bold);
        }
    }
}
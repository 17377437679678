@import "../../../../styles/styles.scss";

.navbar-modules {

    padding-top: 0.5rem;
    background-color: #F1F1F8;
    padding-bottom: 10px;
    width: 100%;
    height: 48px;

    &__container-logo {
        margin-left: 1.5rem;
    }

    &__img-logo {
        height: 30px;
    }
}

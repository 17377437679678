@import '../../../../../shared/styles/styles.scss';

.change-data-form {
    display: grid;
    padding: 25px 30px;
    grid-template-rows: 35px auto 35px auto;
    row-gap: 20px;

    &__header {
        display: inline-flex;
        align-items: center;

        &__icon {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            display: grid;
            place-items: center;
            background-color: $color-athens-gray;
            margin-right: 5px;
        }

        button {
            display: inline-flex;
            align-items: center;

            @include global-typography($color-meteorite, 14px, 16px, bold)
        }
    }

    &__description {

        text-align: center;
        @include global-typography($color-amethyst-smoke, 16px, 14px, 500);

        p:last-child {
            margin: 0;
        }
    }

    &__title {
        text-align: center;
        @include global-typography($color-meteorite, 22px, 20px, bold)
    }

    &__form {
        min-height: 157px;
        border-radius: 8px;
        background-color: $color-white;
        box-shadow: 0px 0px 10px 0px $color-box-shadow;

        display: grid;
        grid-template-rows: 1fr 1fr;
        padding: 15px;

        &__field {
            display: inline-flex;

            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            height: 60px;
            // padding-top: 30px;

            &__input {
                position: relative;
                width: calc(100% - 70px);

                &__label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: 0.3s;
                    @include global-typography($color-meteorite, 18px, 16px, bold)
                }

                input {
                    width: 100%;
                    height: 25px;
                    border-radius: 0;
                    border: none;
                    transition: 0.2s;
                    border-bottom: 1px solid $color-amethyst-smoke;
                    @include global-typography($color-amethyst-smoke, 18px, 16px, bold);

                    &::placeholder {
                        color: transparent;
                    }

                    &:focus-visible {
                        outline: 0;
                    }

                    &:focus~.change-data-form__form__field__input__label {
                        top: -25px;
                        left: -65px;
                    }

                    &[value]:not([value=""])~.change-data-form__form__field__input__label {
                        top: -25px;
                        left: -65px;
                    }

                    &:required,
                    &:invalid {
                        box-shadow: none;
                    }
                }

            }

            &__select {
                width: 56px;
                border-bottom: 1px solid $color-amethyst-smoke;

                &>.ant-select-selector>.ant-select-selection-item {
                    @include global-typography($color-amethyst-smoke, 18px, 16px, bold);
                }
            }

            &__input-phone {

                background-color: $color-white;
    
                &__content {
                    justify-content: space-between;     
    
                    &>div.MuiFormControl-root {
                        width: 80%;
                    }
                }
        
                &.react-tel-input {
                    width: 10%;
                    display: flex;
                    align-items: flex-end;
        
                    &>div.flag-dropdown {
                        background: transparent;
                        border: 0;
                        width: 52px;
        
                        &>div.selected-flag {
                            background: transparent;
        
                            &>div.flag {
                                background: transparent;
                                margin-top: 8px;
        
                                &>div.arrow {
                                    top: 20%;
                                    left: 32px;
                                }
                            }
                        }
                    }
        
                    &>input.form-control {
                        box-shadow: none;
                        padding-left: 4px;
                        padding-bottom: 13px;
                        width: 52px;
                        border: 0;
                        border-bottom: 1px solid $color-amethyst-smoke;
                        border-radius: 0;
                        @include global-typography($color-amethyst-smoke, 23px,18px, bold);
                        padding-top: 25px;
                        background-color: $color-white;
        
                        &::placeholder {
                            @include global-typography($color-meteorite,14px,16px,normal);
                        }
                    }
                }
            }

        }

        &__error{
            width: 100%;
            height: 20px;
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;
            padding-top: 5px;
            @include global-typography($color-error-rose, 10px, 12px, 500)
        }

        &__button {
            margin-top: 30px;
            padding: 0 20px;

            button {
                @include global-button(43px, 100%);
                @include global-typography($color-white, 20px, 18px, bold)
            }
        }
    }

    &__loading{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-overlay-amethyst-smoke;
        display: grid;
        place-items: center;

        &__spinner{
            animation: spinner 1.5s linear infinite;
        }
    }
}
/* Global Styles */

// Colors
$color-royal-blue: #5F3EF2;
$color-royal-blue-dark: #5F3EF2;
$color-dull-lavender: #9D8AE9;
$color-meteorite:#371f7a;
$color-yellow-orange: #Ffa849;
$color-energy-yellow: #F7d468;
$color-blue-haze:#C7c6dc; 
$color-amethyst-smoke:#9389B1; 
$color-rum: #716690;
$color-whisper:#F1F1F8;
$color-athens-gray:#E8E8F1;
$color-athens-gray-light:#F6F6F8;
$color-cream-can:#F6d368;
$color-box-shadow: #C7C6DA88;
$color-white: #FFFFFF;
$color-blurring: #FFFFFF00;
$color-overlay-amethyst-smoke:#9389b1a1;
$color-error-rose: #FF0058;
$color-cadet-blue: #b1b0c6;
$color-periwinkle-gray:#D0d0ef;

// Gradient
@mixin gradient-dull-royal {
    background: transparent linear-gradient(145deg, $color-dull-lavender 0%, $color-royal-blue 100%) 0% 0% no-repeat padding-box;
}

@mixin gradient-cream-yellow {
    background: transparent linear-gradient(137deg, $color-cream-can 0%, $color-yellow-orange 100%) 0% 0% no-repeat padding-box;
}

@mixin gradient-white-athens {
    background: transparent linear-gradient(180deg, $color-blurring 0%, $color-athens-gray-light 96%) 0% 0% no-repeat padding-box;
}

@mixin gradient-royal-dull {
    background: transparent linear-gradient(180deg, $color-royal-blue 0%, $color-dull-lavender 100%) 0% 0% no-repeat padding-box;
}

@mixin gradient-royal-blue {
    border-image: linear-gradient(90deg, $color-dull-lavender 0%, $color-royal-blue-dark 100%) 1;
}

// Typography
@mixin global-typography($color:null,$line-height:null,$size:null,$weight:null) {
    font-style: normal;
    font-variant: normal;
    /* font-family: "Space Grotesk"; */
    letter-spacing: 0px;
    opacity: 1;
    @if $weight {
        font-weight: $weight;
    }
    @if $line-height {
        line-height: $line-height;
    }
    @if $size {
        font-size: $size;
    }
    @if $color {
        color: $color;
    }
}

// Buttons
@mixin global-button($height:auto,$width:auto) {
    background: $color-royal-blue 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    height: $height;
    width: $width;

    &:disabled{
        background: $color-blue-haze 0% 0% no-repeat padding-box;
        color: $color-amethyst-smoke;
    }

}

//Modal
@mixin global-modal-overlay{
    background-color: $color-overlay-amethyst-smoke;
    backdrop-filter: blur(3px);
}

// Animations
@mixin global-animation-header{
    animation-name:animation-header;
}
@mixin global-animation-img{
    animation-name:animation-img;
}
@mixin global-animation-text{
    animation-name:animation-img;
}

// Keyframes
@keyframes animation-header {
    0%{ height: 120%; opacity: 0.5; border-radius: 0; }
    1%{ height: 100%; }
    70%{ height: 100%; }
    72%{ border-radius: 0; }
    100%{ height: 40%; opacity: 1; border-radius: 0% 0% 3% 3%; }
}
@keyframes animation-img{
    1% { visibility: visible; opacity: 0.1;  }
    100% { visibility: hidden ; }
}
@keyframes animation-text {
    1% {visibility: visible; }
    40%{visibility: visible; opacity: 0.5;}
    100% {visibility: hidden;opacity: 0; }
}

// Pop-up card
@mixin global-pop-up-card{
    width: 96%;
    max-height: 100px;
    overflow: hidden;
    transition: max-height 409ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    align-content: flex-start;
    background: $color-white;
    border-radius: 0 0 5px 5px;
}

@mixin global-pop-up-card__open{
    @include global-pop-up-card;
    max-height: calc(100px * 3);
}

// Old Styles
//Global

$global-font-size: 100%;
$global-width: rem-calc(1200);
$global-lineheight: 1.5;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;

//Colors

$primary-colors: #1779ba;
$secondary-colors: #767676;
$success-colors: #3adb76;
$warning-colors: #ffae00;
$alert-colors: #cc4b37;
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #0a0a0a;
$white: #fefefe;

//Body

$body-background: $white;
$body-font-color: $black;
$body-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;


//Accordion

$accordion-background: $white;
$accordion-title-font-size: rem-calc(12);
$accordion-item-color: $primary-colors;
$accordion-item-background-hover: $light-gray;
$accordion-item-padding: 1.25rem 1rem;
$accordion-content-background: $white;
$accordion-content-border: 1px solid $light-gray;
$accordion-content-color: $body-font-color;
$accordion-content-padding: 1rem;

//Badge

$badge-background: $primary-colors;
$badge-color: $white;
$badge-color-alt: $black;
$badge-padding: 0.3em;
$badge-minwidth: 2.1em;
$badge-font-size: 0.6rem;

//Button

$button-font-family: inherit;
$button-padding: 0.85em 1em;
$button-margin: 0 0 $global-margin 0;
$button-fill: solid;
$button-background: $primary-colors;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: $white;
$button-color-alt: $black;
$button-radius: $global-radius;
$button-border: 1px solid transparent;
$button-hollow-border-width: 1px;
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: -20%;
$button-hollow-hover-lightness: -50%;
$button-transition: background-color 0.25s ease-out, color 0.25s ease-out;

//Card

$card-background: $white;
$card-font-color: $body-font-color;
$card-divider-background: $light-gray;
$card-border: 1px solid $light-gray;
$card-shadow: none;
$card-border-radius: $global-radius;
$card-padding: $global-padding;
$card-margin-bottom: $global-margin;


//Dropdown

$dropdown-padding: 1rem;
$dropdown-background: $body-background;
$dropdown-border: 1px solid $medium-gray;
$dropdown-font-size: 1rem;
$dropdown-width: 300px;
$dropdown-radius: $global-radius;


//Label

$label-background: $primary-colors;
$label-color: $white;
$label-color-alt: $black;
$label-font-size: 0.8rem;
$label-padding: 0.33333rem 0.5rem;
$label-radius: $global-radius;


//media queries

$media-query-small: 639px;
$media-query-medium: 640px;
$media-query-large: 1023px;
$media-query-xlarge: 1024px;
$media-query-xxlarge: 1199px;
$media-query-xxxlarge: 1200px;

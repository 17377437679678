@import "../../../../../shared/styles/styles.scss";

.work-profile {

    &__title{
        @include global-typography($color-meteorite,20px,18px, bold);
    }

    &__title-pdf{
        @include global-typography($color-royal-blue,20px,18px, bold);
    }

    &__description{
        border-bottom: 1px solid $color-blue-haze;
        margin-bottom: 7%;
        @include global-typography($color-amethyst-smoke,20px,18px, bold);

        &--mod{
            border: 0;
        }
    }

    &__btn{
        margin: 12px 0;
        
        &--view-pdf{
            width: 166px;
        }
    }

    &__services{
        &:disabled{
            background: $color-whisper;
            border-radius: 10px;
            box-shadow: none;
            padding: 5px;
        }
    }

    &__content-textarea {
        width: 95%;
        height: 90%;
        margin: 0px;
        resize: none;
        border-radius: 5px;
        border: 0;
        box-shadow: none;
        background: $color-cadet-blue;
        @include global-typography($color-amethyst-smoke,20px,18px,bold);

        &:focus{
            border: 0;
            box-shadow: none;
            background: $color-whisper;
        }

        &::placeholder{
            @include global-typography($color-amethyst-smoke,20px,18px,bold);
        }
    };

    &__info {
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;
        border: 1px solid $color-blue-haze;
        margin-top: 10px;
        &--mod{
            @extend .work-profile__info;
            border-color: $color-error-rose
        }
    };
}

//media query
@media screen and (max-width: $media-query-medium) {  
    .work-profile {
        height: 31vh;

        &__content{
            height: 100%;
            overflow-y: scroll;
    
            &::-webkit-scrollbar{
                display: none;
            }
        }
    }
    
} 
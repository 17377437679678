@import "../../../shared/styles/styles.scss";

.welcome {
    &__header {
        width: 100%;
        padding: 20px 20px 10px 20px;

        &__logo {
            background-color: $white;
            height: 150px;
            width: 150px;
            border-radius: 15px;

            &__content {
                width: 100%;
                padding-top: 25px;
            }
        }
    }

    &__footer {
        width: 100%;
        padding: 0 20px;
        justify-content: center;
        height: 65vh;

        &__title {
            @include global-typography($color-meteorite, 20px, 20px, bold);
            text-align: center;
            display: flex;
            align-items: center;
        }

        &__text {
            @include global-typography($color-amethyst-smoke, 18px, 18px, 500);
            text-align: center;
            padding: 0 20px;
        }

        &__content {
            width: 90%;
            border-radius: 8px;
            margin-top: 10px;
            box-shadow: 0px 0px 12px $color-box-shadow;
        }

        &__icon {
            height: 71px;
            width: 71px;
            margin-top: -35px;
        }

        &__label {
            @include global-typography($color-amethyst-smoke, 18px, 18px, bold);
            margin: 2% 0;
        }

        &__container {
            width: 80%;
        }

        &__button {
            @include global-button(42px, 80%);
            @include global-typography($color-whisper, 18px, 20px, bold);
            margin: 5% 0 -2%;

            &__content {
                width: 100%;
            }
        }

        &__checkbox-container {
            margin: 20px 0;

            &__text {
                margin-left: 10px;
                @include global-typography($color-amethyst-smoke, 14px, 12px, bold);

                button {
                    margin-left: 4px;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                    @include global-typography($color-royal-blue-dark, 14px, 12px, bold);
                }
            }
        }

        &__back {
            @include global-typography($color-amethyst-smoke, 18px, 18px, normal);
        }
    }
}

//media query
//landscape

@media screen and (orientation: landscape) {
    .welcome {
        &__footer {
            height: auto;
            padding: 20px;

            &__container {
                margin: 10px 0;
            }

            &__button {
                &__content {
                    margin: 10px 0;
                }
            }
        }
    }
}
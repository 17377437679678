@import "../../../../../../shared/styles/styles.scss";

.voting-data{

    &__loading{
        width: 100%; 
        height: 250px;
        background-color: $color-whisper;
        
        &-spinner{
            animation: spinner 1.5s linear infinite;
        }

        &__text{
            @include global-typography($color-meteorite, 18px, 18px, bold);
            padding-top:40px;
        }
    }

    &__content{
       margin: -20px;
    }

    
    &__content-map {
        width: 100%;
        height: 300px;
    }

    &__box-content {
        width: 100%;
        overflow-y: scroll;
        height: 100%;
        box-shadow: 0px 0px 12px $color-box-shadow;
        border-radius: 10px;
        opacity: 1;
        background-color: $color-white;
        padding: 20px;
        padding-bottom: 140px;
        &::-webkit-scrollbar{
            display: none;
        }
    }

    &__input.MuiFormControl-root {

        width: 100%;

        &>label {

            z-index: 1;

            &.MuiFormLabel-root {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -15px;
                left: -10px;
                &+div>div{
                    top: 15px;
                    right: 0;
                }
            }

            &.MuiInputLabel-shrink {
                @include global-typography($color-meteorite, 18px, 18px, bold);
                top: -5px;
                transform: translate(14px, -14px);

                &+div>div{
                    top: auto;
                }
            }

            &.Mui-disabled {
                color: $color-amethyst-smoke;
            }
        }


        &>div {

            &:hover {
                &>fieldset {
                    border-color: $color-amethyst-smoke;
                }
            }

            &.Mui-focused {
                &>fieldset {
                    border-bottom: 2px solid $color-blue-haze;
                }
            }

            &.Mui-error {
                &>input {
                    &+fieldset {
                        border-bottom: 1px solid $color-blue-haze;
                    }
                }
            }

            &>input {
                padding: 0 0 0 4px;
                box-shadow: none;
                border: 0;
                background: transparent;
                cursor: text;
                @include global-typography($color-amethyst-smoke, 20px, 18px, bold);
                -webkit-text-fill-color: $color-amethyst-smoke;

                &:disabled {
                    background-color: transparent;
                }

                &+fieldset {
                    border: 0;
                    border-bottom: 1px solid $color-blue-haze;
                    border-radius: 0;
                }
            }

            &.Mui-disabled {
                background-color: $color-blue-haze;
            }

        }

    }

    &__input-autocomplete.MuiFormControl-root {
        @extend .voting-data__input;
        padding-left: 0;

        &>label {

            &.MuiFormLabel-root {
                left: -10px;
                top: 0;
            }
        }

        &>div{
            padding: 0;


            &>fieldset {
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid $color-blue-haze;
            }
        }

        &>.Mui-error {
            padding: 0;


            &>fieldset {
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid $color-error-rose;
            }
        }
    }
}
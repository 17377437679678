@import "../../../../../shared/styles/styles.scss";


.members-filter{

    &__content{
        height: 90vh;
    }

    &__container {
        background-color: $color-whisper;
        width: 100%;
        padding: 20px;
    }

    &__back{
        z-index: 1;
        &__img{
            transform: matrix(-1, 0, 0, -1, -1, -1);
        }
    }

    &__header {

        &__content {
            width: 99%;
            text-align: center;
            margin-left: -16px;
        }

        &__icon {
            color: $color-royal-blue;
            margin-right: 10px;
        }

        &__text {
            
            color: $color-meteorite;
            @include global-typography($color-meteorite,20px,20px,bold);
        }
    }

    &__footer{
        height: 90%;
        width: 95%;
        padding: 10px 10px 0;
        background-color: $color-white;
    }

    &__members-container {

        width: 100%;
        flex-direction: column;
        align-items: center;
        display: flex;
        transition: 0.2s ease-out;
        justify-content: flex-start;
        height: 84%;

        &__chevron {
            transition: transform 0.6s ease-in-out;
            transform: rotate(180deg);

            &--mod {
                transition: transform 0.6s ease-in-out;
                transform: rotate(0deg);
            }
        }

        &--associated {
            background-color: $color-white;
            margin-bottom: 50px;
        }

        &__header {
            width: 100%;
            background: $color-white;
            padding: 0% 3%;
            justify-content: space-between;
            border-radius: 8px 8px 0px 0px;
                
            &__level-container {
                display: flex;
                flex-direction: column;
                width: 49%;
            }
            
            &__counter-container {
                display: flex;
                width: 45%;
            }
        
            &__text {
                margin: 0;
                @include global-typography($color-meteorite, 18px, 18px, 500);
                
                &__label {
                    @include global-typography($color-amethyst-smoke, 14px, 14px, 500);
                    &__strong {
                        @include global-typography($color-amethyst-smoke, 12px, 14px, bold);
                        width: 90%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        &__items {
            background-color: rgb(255, 255, 255);
            border-radius: 0 0 8px;
            align-items: center;
            transition: 0.2s ease-out;
            margin: 10px 10px 0 10px;

            &--associated {
                max-height: 80%;
                overflow: auto;
            }

            &__button {
                margin: 10;

                &__icon {
                    transform: rotate(270deg);
                }
            }

            &--active {
                overflow: auto;
            }

        }

        &__details {

            &--img {
                height: 50px;
                width: 50px;
                border-radius: 50px;
                margin-right: 5px;
            }

            &__number-votes {
                color: white;
                display: flex;
                background-color: rgb(107, 106, 106);
                padding: 4px;
                margin-left: 60%;
                margin-top: -25%;
                margin-bottom: 0;
                width: 20px;
                height: 20px;
                font-size: 0.8rem;
                border-radius: 40px;
                align-items: center;
                justify-content: center;
            }

            &__text {
                text-align: center;
                width: 90%;
                height: 36px;
                margin-bottom: 0px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                @include global-typography($color-meteorite,18px,16px,normal);

                &--inactive {
                    @extend .members-filter__members-container__details__text;
                    opacity: 0.5;
                }
            }
        }

        &__more {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: 50px;
            border-radius: 50px;
            margin-right: 5px;
            background-color: #B9B9B9;
            color: $color-white;
        }

    }

    &__pending-icon{
        margin-left: 50%;
        margin-top: -26%;
        margin-bottom: 0;
        width: 20px;
        height: 20px;
        z-index: 1;
    }


    &__member-associate {

        &__input-container {
            width: 85%;
            position: relative;
        }

        &__input {
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 2.4375rem;
            padding: 0.5rem 1.7rem;
            background-color: $color-white;
            font-family: inherit;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $color-meteorite;
            transition: box-shadow .5s, border-color .25s ease-in-out;
            appearance: none;
            border-width: 0px;
            border-bottom-color: $color-whisper;
            border-bottom-width: 2px;
            margin: 10px 0 0 0;
            box-shadow: none;

            &--icon {
                bottom: 0.8rem;
                position: absolute;
                color: $color-blue-haze;
                opacity: 1;
                width: 28px;
            }

        }
    }

    &__input.MuiFormControl-root {

        margin: 0 0 3px;

        &>label {

            &.MuiFormLabel-root {
                color: $dark-gray;
                font-size: 16px;

                &.Mui-error {
                    color: red;
                }
            }

            &.Mui-focused {
                font-size: 13px;
                color: $dark-gray;
            }
        }


        &>div {

            &.MuiInput-underline {

                &.Mui-error {
                    &::after {
                        border-bottom: 1px solid $dark-gray;
                    }
                }

                &::after {

                    border-bottom: 2px solid $dark-gray;
                }
            }

            &>input {
                padding: 0;
                padding-bottom: 8px;
                box-shadow: none;
                font-size: 16px;
                color: rgb(80, 80, 80);
                border: 0;
                background: transparent;
                cursor: text;

                &:disabled {
                    background-color: transparent;
                }
            }

            &.MuiInput-underline.Mui-disabled:before {
                border-bottom-style: solid;
            }

            &.MuiInputBase-root.Mui-disabled {
                background-color: #f5f5f5;
                opacity: 0.5;
            }

        }

    }

    &__details-container {
        display: flex;
        flex-direction: column;
        background-color: rgb(230, 230, 230);
        padding: 5px;
        align-items: center;
        color: rgb(107, 107, 107);

        &__select:focus {
            box-shadow: none;
            border-width: 0px;
            border-bottom-color: rgb(139, 139, 139);
            border-bottom-width: 1px;
            margin: 0;
        }

        &__select {
            border-width: 0px;
            border-bottom-color: rgb(139, 139, 139);
            border-bottom-width: 1px;
            margin: 0;
        }


        &__no-data {
            display: flex;
            text-align: center;
            width: 300px;
            height: 110px;
            color: #bbbbbb;
            border-style: dashed;
            justify-content: center;
            align-items: center;
        }

        &__text {
            height: 40%;
            width: 80px;
            color: $color-royal-blue;
            font-size: 0.7rem;
            overflow: auto;

            &--noh {
                justify-content: flex-end;
                color: #707070
            }

            &--mod {
                height: 40px;
                width: 88px;
                text-align: center;
            }

            &--filter {
                margin-left: 3px;
                font-size: 0.9rem;
                margin-bottom: 0;
            }

            &__no-data {
                color: #bbbbbb;
                width: 160px;

                &--icon-search {
                    display: flex;
                    justify-content: flex-end;
                    margin: 5px;

                    >button {
                        color: #707070
                    }
                }

                &--select {
                    margin-right: 12px;
                }
            }

            &__select {
                display: flex;
                flex-direction: column;
            }
        }

        &__details-filters {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &__image {
                margin-top: 3px;
            }
        }

        &__details-user {
            display: flex;
            flex-direction: column;
            padding: 5px;
            height: 100px;
            align-items: center;
            justify-content: space-around;
        }

        &__number-votes {
            color: white;
            display: flex;
            background-color: rgb(107, 106, 106);
            padding: 4px;
            margin-left: 48%;
            margin-top: -25%;
            margin-bottom: 0;
            width: 20px;
            height: 20px;
            font-size: 0.8rem;
            border-radius: 40px;
            align-items: center;
            justify-content: center;
        }

        &__collapse-icon {
            transition: transform 0.6s ease-in-out;
            transform: rotate(0deg);

            &--activated {
                transition: transform 0.6s ease-in-out;
                transform: rotate(180deg);
            }
        }

        &__search-container {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            width: 98%;
        }
    }

    &__member {
        background-color: $white;
        width: 100%;
        height: 97px;
        margin-top: 1%;
        border-radius: 10px;

        &__header {
            width: 95%;
            padding: 2%;
            background: white;
            margin-top: 5px;
            justify-content: space-between;
            border-radius: 8px 8px 0px 0px;
        }

        &__subtitle {
            font-size: 10px;
            color: $dark-gray;
        }

        &__icon {
            color: $dark-gray;
            margin-left: 5px;
            margin-top: 2px;

            &__more{
                margin-left: 3px;
                font-size: 8px;
                color: #8a8a8a;
                font-weight: bold;
            }

            &--mod {
                margin-top: 4px;
            }

            &__num {
                margin-left: -10px;
                font-size: 8px;
                color: $dark-gray;
                font-weight: bold;

                &--mod{
                    margin-left: -12px;
                    font-size: 7px;
                }
            }
        }

        &__img {
            width: 50.24px;
            height: 50.24px;
            color: $medium-gray;
            border-radius: 30px;

            &--mod {
                color: #e4e4e4
            }

            &--inactive{
                opacity: 0.5;
            }
        }

        &__content {
            margin-top: 1%;

            &__view-more {
                display: flex;
                position: relative;
                flex-direction: column;
                padding: 5px;
            }
        }

        &__name {
            color: $dark-gray;
            color: rgb(109, 109, 109);
            text-align: center;
            margin-bottom: 0px;
            font-size: 0.7rem;
            height: 22px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            &--mod {
                color: #e4e4e4
            }
        }

        &__button {
            width: 90px;

            &--active {
                background: #EEEEEE;
                border-radius: 10px;
            }
        }

        &__more {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            border-radius: 50px;
            margin: 0 auto;
            background-color: #B9B9B9;
            color: white;


            &__text {
                color: rgb(109, 109, 109);
                text-align: center;
                margin-bottom: 0px;
                font-size: 0.7rem;
                width: 55px;

            }
        }

    }


    &__number-member {
        color: white;
        display: flex;
        background-color: $color-royal-blue;
        padding: 4px;
        margin-left: 40%;
        margin-top: -35%;
        margin-bottom: 0;
        width: 20px;
        height: 20px;
        font-size: 0.8rem;
        border-radius: 40px;
        align-items: center;
        justify-content: center;
    }

}
.loading-globally{

    &__container{
        display: flex;
        justify-content: center;
        align-items: center;

        &__icon-loading{
            height: 30px;
            width: 30px;
            animation: spinner 1.5s linear infinite;
        }
    }
}

//media queries
// //Small
@media screen and (max-width: $media-query-small) {
    .members-filter {

        &__number-member {

            &--mod {
                font-size: 0.5rem;
            }
        }
    }
}

//Medium
@media screen and (min-width: $media-query-medium) {
    .members-filter {
        &__container {
            &--mod{
                display: none;
            }
        }
    }
}

//Landscape 
@media screen and (orientation: landscape) {
    .members-filter {
        &__members-container {
            &__items{
                padding-bottom: 15%;
            }
        }
    }
}
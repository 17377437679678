@import "../../../../../shared/styles/styles.scss";

.voting-information {

    &__content{
        height: 100%;
        overflow-y: scroll;

        &::-webkit-scrollbar{
            display: none;
        }
    }
    
    &__title{
        @include global-typography($color-meteorite,20px,18px, bold);
    }

    &__description{
        border-bottom: 1px solid $color-blue-haze;
        margin-bottom: 7%;
        @include global-typography($color-amethyst-smoke,20px,18px, bold);
    }
}

@media screen and (max-width: $media-query-medium) {  
    .voting-information{
        height: 31vh;
    }
} 